import React, {Component} from "react";
import {Container} from "reactstrap";
import PropTypes from "prop-types";

import "../scss/InfoPage.scss";
import WomanReading from "../components/img/WomanReading";

class InfoPage extends Component {
	render() {
		return (
			<div className="main info">
				<section id="woman-reading-m" className="woman-reading d-md-none">
					<WomanReading id="woman-reading-m-img" />
				</section>

				<section className="info-section">
					<span id="top-left-square" />
					<span id="bottom-left-square" />

					<Container className="info-content">
						{this.props.children}
						<section id="woman-reading" className="woman-reading desktop">
							<WomanReading id="woman-reading-img" />
						</section>
					</Container>
				</section>

				<span id="bottom-slant" className="desktop" />
				<span id="woman-spacer" className="desktop" />
			</div>
		);
	}
}

InfoPage.propTypes = {
	children: PropTypes.node.isRequired,
};

export default InfoPage;
