import React, {Component} from "react";
import {Row, Col} from "reactstrap";
import {Link} from "react-router-dom";

import "../scss/Login.scss";
import {addPageHead} from "../config";
import FormPage from "../components/FormPage";
import LoginForm from "../components/LoginForm";
import LoginSignupDesc from "../components/LoginSignupDesc";
import ManReading from "../components/img/ManReading";

class Login extends Component {
	render() {
		return (
			<FormPage image={<ManReading />} description={<LoginSignupDesc />}>
				{addPageHead("Log In")}
				<Col>
					<h1>Log In</h1>
					<LoginForm />
					<Row className="separator">or</Row>
					<Link to="/signup" className="btn btn-secondary">
						Sign Up
					</Link>
					<Row>
						<Link
							id="reset-password-link"
							to="/account/reset-password"
							className="secondary"
						>
							Forgot your password?
						</Link>
					</Row>
				</Col>
			</FormPage>
		);
	}
}

export default Login;
