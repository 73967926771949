import {CONTACT} from "../actions/types";

// const _ = require("lodash");

export default (state = {}, action) => {
	const {type, payload} = action;
	switch (type) {
	case CONTACT:
		return {...state, response: payload.response};
	default:
		return state;
	}
};
