import React from "react";
import PropTypes from "prop-types";
import ManLounging from "./img/ManLounging";

const ErrorWrapper = ({children}) => {
	return (
		<div className="main formpage contact align-items-stretch">
			<div className="main error">
				<div className="error-code">{children}</div>
			</div>
			<span id="bottom-slant" />
			<div className="error-image d-flex align-items-center justify-content-center">
				<ManLounging />
			</div>
		</div>
	);
};

export default ErrorWrapper;

ErrorWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};
