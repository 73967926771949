import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Container, Row, Col} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import "../../scss/Account.scss";
import {
	clearReadingSpeedTest,
	getReadingSpeedTest,
	updateReadingSpeedTest,
} from "../../actions";
import {addPageHead} from "../../config";
import {READING_SPEED} from "./types";
import {readingTestType} from "../../types";
import AccountWrapper from "../../components/AccountWrapper";
import Button from "../../components/lib/Button";
import ReadingTest from "../../components/ReadingTest";
import Spinner from "../../components/lib/Spinner";

class ReadingTestPage extends Component {
	state = {
		showTest: false,
	};
	componentDidMount() {
		const {id = null} = this.props.match.params;
		this.props.clearReadingSpeedTest();
		if (id !== null) {
			this.props.getReadingSpeedTest({testId: id});
		} else {
			this.props.getReadingSpeedTest();
		}
	}
	renderReadingTest(test) {
		if (test === null) {
			return <Spinner />;
		}
		return (
			<Fragment>
				<h2>{test.name}</h2>
				<ReadingTest {...test} showTitle={false} showTopButton={false} />

				<Row>
					<Col md={8} lg={6}>
						<Link to="/account/reading-speed">
							<Button color="primary" outline={true}>
								<FontAwesomeIcon icon={["far", "stopwatch"]} />
								Select another passage
							</Button>
						</Link>
					</Col>
				</Row>
			</Fragment>
		);
	}

	render() {
		const {readingTest} = this.props;
		return (
			<AccountWrapper page={READING_SPEED}>
				{addPageHead("Reading Speed")}
				<h1 className="content-title">Reading Speed Test</h1>
				<Container className="wrapper">
					{this.renderReadingTest(readingTest)}
				</Container>
			</AccountWrapper>
		);
	}
}

ReadingTestPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	readingTest: PropTypes.shape(readingTestType),
	clearReadingSpeedTest: PropTypes.func.isRequired,
	getReadingSpeedTest: PropTypes.func.isRequired,
	updateReadingSpeedTest: PropTypes.func.isRequired,
};

function mapStateToProps({readingTest: {currentTest}}) {
	return {readingTest: currentTest};
}

export default connect(mapStateToProps, {
	clearReadingSpeedTest,
	getReadingSpeedTest,
	updateReadingSpeedTest,
})(ReadingTestPage);
