import Cookies from "universal-cookie";
import {
	FOUR_YEARS_IN_SECONDS,
	HLTR_TOKEN_COOKIE,
	ONE_YEAR_IN_SECONDS,
	PERSONALIZED_READING_SPEED_COOKIE,
} from "../constants";

const cookies = new Cookies();

function getPersonalizedReadingSpeedFromCookie() {
	const fromCookies = cookies.get(PERSONALIZED_READING_SPEED_COOKIE);

	return Number(fromCookies) ?? null;
}

function setPersonalizedReadingSpeedToCookie(speedInWpm) {
	cookies.set(PERSONALIZED_READING_SPEED_COOKIE, speedInWpm, {
		path: "/",
		maxAge: FOUR_YEARS_IN_SECONDS,
	});
}

function removePersonalizedReadingSpeedFromCookie() {
	cookies.remove(PERSONALIZED_READING_SPEED_COOKIE, {path: "/"});
}

function setTokenToCookie(token) {
	cookies.set(HLTR_TOKEN_COOKIE, token, {
		path: "/",
		maxAge: ONE_YEAR_IN_SECONDS,
	});
}

function getTokenFromCookie() {
	return cookies.get(HLTR_TOKEN_COOKIE);
}

function removeTokenFromCookie() {
	cookies.remove(HLTR_TOKEN_COOKIE, {path: "/"});
}

export {
	setTokenToCookie,
	getTokenFromCookie,
	removeTokenFromCookie,
	removePersonalizedReadingSpeedFromCookie,
	setPersonalizedReadingSpeedToCookie,
	getPersonalizedReadingSpeedFromCookie,
};
