import {combineReducers} from "redux";
import accountReducer from "./accountReducer";
import bookReducer from "./bookReducer";
import contactReducer from "./contactReducer";
import {reducer as formReducer} from "redux-form";
import suggestionReducer from "./suggestionReducer";
import readingTestReducer from "./readingTestReducer";

export default combineReducers({
	account: accountReducer,
	book: bookReducer,
	contact: contactReducer,
	form: formReducer,
	suggestion: suggestionReducer,
	readingTest: readingTestReducer,
});
