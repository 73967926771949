import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Form, FormFeedback} from "reactstrap";
import PropTypes from "prop-types";

import {contact} from "../actions";
import {formType} from "../types";
import Input from "../components/lib/Input";
import Button from "../components/lib/Button";

class ContactForm extends Component {
	onSubmit = formValues => {
		return this.props.contact(formValues);
	};
	responseMessage(response) {
		if (response === "success") {
			return (
				<FormFeedback valid={true} style={{display: "block"}}>
					Your message was sent successfully
				</FormFeedback>
			);
		}
		return (
			<FormFeedback style={{display: "block"}}>
				Something went wrong. Please try again later.
			</FormFeedback>
		);
	}
	render() {
		const {response, submitting} = this.props;
		return (
			<Form onSubmit={this.props.handleSubmit(this.onSubmit)} autoComplete="on">
				<Field id="name" name="name" component={Input} placeholder="Name" />
				<Field id="email" name="email" component={Input} placeholder="Email" />
				<Field
					id="message"
					name="message"
					component={Input}
					type="textarea"
					placeholder="Message"
					rows={5}
				/>
				<Button color="primary" id="submit" name="submit" disabled={submitting}>
					Send Message
				</Button>
				{response === null ? null : this.responseMessage(response)}
			</Form>
		);
	}
}

ContactForm.propTypes = {
	...formType,
	response: PropTypes.string,
	contact: PropTypes.func.isRequired,
};

const validate = formValues => {
	const {name, email, message} = formValues;
	let errors = {};
	if (!name) {
		errors.name = "Please enter your name";
	}
	if (!email) {
		errors.email = "Please enter your email";
	}
	if (!message) {
		errors.message = "Please enter a message";
	}
	return errors;
};

const formWrapped = reduxForm({
	form: "ContactForm",
	validate,
})(ContactForm);

function mapStateToProps({contact: {response = null}}) {
	return {response};
}

export default connect(mapStateToProps, {contact})(formWrapped);
