import React from "react";
import url from "url";

const ManLounging = props => (
	<img
		alt="Man lounging and reading"
		src={url.resolve(process.env.PUBLIC_URL, "/img/man-lounging.svg")}
		{...props}
	/>
);

export default ManLounging;
