import React, {Component} from "react";
import {Row, Container} from "reactstrap";
import PropTypes from "prop-types";

import "../scss/BookCarousel.scss";
import {bookType} from "../types";
import {bookUrl, humanizeSeconds} from "../config";
import BookImage from "./BookImage";
import Spinner from "./lib/Spinner";

const MAX_COLS = 5;

class BookCarousel extends Component {
	renderBookContainer = b => {
		const {displayReadingTime, openInNew} = this.props;
		const {averageReadingTime = null, personalizedReadingTime = null} = b;
		const image = b.smallImage || b.image || null;
		return (
			<div key={b.id} className="book-container d-flex flex-column">
				<BookImage
					{...b}
					image={image}
					link={bookUrl(b.id, b.title)}
					openInNew={openInNew}
				/>
				{!displayReadingTime || personalizedReadingTime === null ? null : (
					<p>Your reading time: {humanizeSeconds(personalizedReadingTime)}</p>
				)}
				{!displayReadingTime || averageReadingTime === null ? null : (
					<p>Typical reading time: {humanizeSeconds(averageReadingTime)}</p>
				)}
			</div>
		);
	};

	renderBooks(books, allowExpand) {
		const {allowCarousel} = this.props;
		// do not slice books if carousel is allowed
		const booksToRender = allowCarousel ? books : books.slice(0, MAX_COLS);
		return books.length === 0
			? "No results"
			: booksToRender.map(this.renderBookContainer);
	}

	render() {
		const {formattedName, name, books, allowExpand, showSpinner} = this.props;
		if (books === null) {
			return showSpinner ? <Spinner /> : null;
		}
		const displayName = formattedName || name || null;
		return (
			<Container>
				{displayName === null ? null : (
					<Row>
						<h2>{displayName}</h2>
					</Row>
				)}
				<Row
					className={
						books.length > MAX_COLS
							? "book-carousel-container"
							: "book-carousel"
					}
				>
					{this.renderBooks(books, allowExpand)}
				</Row>
			</Container>
		);
	}
}

BookCarousel.defaultProps = {
	allowExpand: false,
	displayReadingTime: false,
	showSpinner: false,
	openInNew: false,
	allowCarousel: false,
};

BookCarousel.propTypes = {
	allowExpand: PropTypes.bool.isRequired,
	books: PropTypes.arrayOf(PropTypes.shape(bookType)),
	formattedName: PropTypes.string,
	name: PropTypes.string,
	openInNew: PropTypes.bool.isRequired,
	showSpinner: PropTypes.bool.isRequired,
	displayReadingTime: PropTypes.bool.isRequired,
	allowCarousel: PropTypes.bool.isRequired,
};

export default BookCarousel;
