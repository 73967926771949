import {
	GET_GENRES,
	CREATE_SUGGESTION,
	CLEAR_SUGGESTION,
} from "../actions/types";
import {
	EIGHT_HOURS_IN_SECONDS,
	FOUR_HOURS_IN_SECONDS,
	SIX_HOURS_IN_SECONDS,
	TEN_HOURS_IN_SECONDS,
	TWO_HOURS_IN_SECONDS,
} from "../constants";

// const _ = require("lodash");

const INITIAL_STATE = {
	genres: null,
	suggestion: null,
};

const categorizeSuggestionsByTime = suggestions => {
	const intervals = [
		{label: "Under 2 hours", min: 0, max: TWO_HOURS_IN_SECONDS},
		{label: "2-4 hours", min: TWO_HOURS_IN_SECONDS, max: FOUR_HOURS_IN_SECONDS},
		{label: "4-6 hours", min: FOUR_HOURS_IN_SECONDS, max: SIX_HOURS_IN_SECONDS},
		{
			label: "6-8 hours",
			min: SIX_HOURS_IN_SECONDS,
			max: EIGHT_HOURS_IN_SECONDS,
		},
		{
			label: "8-10 hours",
			min: EIGHT_HOURS_IN_SECONDS,
			max: TEN_HOURS_IN_SECONDS,
		},
		{
			label: "More than 10 hours",
			min: TEN_HOURS_IN_SECONDS,
			max: Infinity,
		},
	];

	const categorizedBooksByHours = {};

	intervals.forEach(interval => {
		categorizedBooksByHours[interval.label] = [];
	});

	suggestions.forEach(suggestion => {
		const readingTime = suggestion?.personalizedReadingTime;
		const category = intervals.find(interval => {
			return readingTime >= interval.min && readingTime < interval.max;
		});

		if (category) {
			categorizedBooksByHours[category.label].push(suggestion);
		}
	});
	return categorizedBooksByHours;
};

export default (state = INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
	case GET_GENRES:
		return {...state, genres: payload};
	case CREATE_SUGGESTION:
		return {...state, suggestion: categorizeSuggestionsByTime(payload)};
	case CLEAR_SUGGESTION:
		return {...state, suggestion: null};
	default:
		return state;
	}
};
