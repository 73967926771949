import React from "react";
import {Alert as ReactstrapAlert} from "reactstrap";

const Alert = props => {
	// colours:
	// "primary",
	// "secondary",
	// "success",
	// "danger",
	// "warning",
	// "info",
	// "light",
	// "dark",
	return <ReactstrapAlert {...props} />;
};

export default Alert;
