import {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import history from "../history";
import {tokenLogIn} from "../actions";

class GoodreadsLogin extends Component {
	componentDidMount() {
		const {token} = this.props.match.params;
		this.props.tokenLogIn(token);
		history.push("/account/books");
	}
	render() {
		return null;
	}
}

GoodreadsLogin.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			token: PropTypes.string.isRequired,
		}),
	}),
	tokenLogIn: PropTypes.func.isRequired,
};

export default connect(null, {tokenLogIn})(GoodreadsLogin);
