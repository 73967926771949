import React, {Component} from "react";
import {Row, Col} from "reactstrap";
import PropTypes from "prop-types";

import "../scss/FormPage.scss";

class FormPage extends Component {
	render() {
		return (
			<div className="main formpage align-items-stretch">
				<span id="bottom-slant" />
				<div className="formpage-content-wrapper">
					<div className="formpage-content">
						<Row
							id="formpage-img"
							className="justify-content-center align-items-center d-none d-lg-flex"
						>
							{this.props.image}
						</Row>

						<Row className="m-0">
							<Col className="d-none d-lg-flex" lg={{size: 5}} xl={{size: 6}}>
								{this.props.description}
							</Col>

							<Col
								className="wrapper formpage-form-wrapper"
								lg={{size: 7}}
								xl={{size: 6}}
							>
								<section className="formpage-form">
									{this.props.children}
								</section>
							</Col>
						</Row>

						<Row
							id="formpage-img-m"
							className="d-flex justify-content-center align-items-center d-lg-none"
						>
							{this.props.image}
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

FormPage.propTypes = {
	children: PropTypes.node.isRequired,
	description: PropTypes.node,
	image: PropTypes.node.isRequired,
};

FormPage.defaultProps = {
	description: null,
};

export default FormPage;
