import React from "react";
import {Row, Col} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

import "../scss/Footer.scss";
import OutboundLink from "./OutboundLink";

const SOCIAL_LINKS = [
	{
		name: "Facebook",
		link: "https://www.facebook.com/howlongtoread/",
		icon: "facebook-f",
	},
	// {
	// 	name: "Twitter",
	// 	link: null,
	// 	icon: "twitter",
	// },
	// {
	// 	name: "Instagram",
	// 	link: null,
	// 	icon: "instagram",
	// },
];

class Footer extends React.Component {
	render() {
		return (
			<footer id="sticky-footer" className="py-3">
				<Row>
					<Col md={{size: 7}} id="footer-credits">
						<small>
							Created by Alex Thorburn-Winsor and Harry Tong.{" "}
							<Link to="/disclaimer">
								Read our privacy policy and disclaimer.
							</Link>
						</small>
					</Col>
					<Col md={5} id="footer-text">
						<small id="cr-msg">
							&copy; {new Date().getFullYear()}{" "}
							<span id="footer-text-hltr">How Long to Read</span>
						</small>
						{SOCIAL_LINKS.map(({name, link, icon}) => (
							<OutboundLink
								key={name}
								to={link}
								target="_blank"
								rel="noopener noreferrer"
								eventLabel="social-fb"
							>
								<FontAwesomeIcon
									className="footer-icon"
									icon={["fab", icon]}
									size="xs"
								/>
							</OutboundLink>
						))}
					</Col>
				</Row>
			</footer>
		);
	}
}

export default Footer;
