import React, {Component} from "react";
import PropTypes from "prop-types";
import {Row, Container} from "reactstrap";

import "../scss/Collection.scss";
import {itemType} from "../types";
import BookImage from "./BookImage";
import Spinner from "./lib/Spinner";

// TODO: implement max cols and rows
// const MAX_COLS = 5;
// const MAX_ROWS = 4;

class Collection extends Component {
	renderItem = item => {
		return (
			<div key={item.id} className="item-container d-flex flex-column">
				<BookImage {...item} link={item.link} />
			</div>
		);
	};

	renderItems(items, allowExpand) {
		return items.length === 0 ? "No results" : items.map(this.renderItem);
	}

	render() {
		const {items, allowExpand, alignItems} = this.props;
		if (items === null) {
			return <Spinner />;
		}
		return (
			<Container className="collection">
				<Row className="collection-row" style={{alignItems}}>
					{this.renderItems(items, allowExpand)}
				</Row>
			</Container>
		);
	}
}

Collection.defaultProps = {
	allowExpand: false,
	alignItems: "center",
};

Collection.propTypes = {
	allowExpand: PropTypes.bool,
	alignItems: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.shape(itemType)),
};

export default Collection;
