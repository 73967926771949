import React, {Fragment} from "react";
import {
	Collapse,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Navbar as ReactstrapNavbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
	UncontrolledDropdown,
} from "reactstrap";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import path from "path";
import PropTypes from "prop-types";

import "../scss/Navbar.scss";
import {getAccount} from "../actions";
import CoverImage from "../components/CoverImage";
import {TABS as ACCOUNT_TABS} from "../pages/account/types";
import SearchForm from "../components/SearchForm";

const Logo = props => (
	<img
		alt="How Long to Read logo"
		src={path.join(process.env.PUBLIC_URL, "/img/hltr-logo.svg")}
		{...props}
	/>
);

const HIDE_SEARCH_ON_PATHS = ["/results"];

const LINKS = [
	{
		page: "Need a book?",
		link: "/recommended",
	},
	{
		page: "About",
		link: "/about",
	},
	{
		page: "Contact",
		link: "/contact",
	},
];

class Navbar extends React.Component {
	state = {
		isOpen: false,
	};
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.renderAccountNavItem = this.renderAccountNavItem.bind(this);
	}

	shouldHideSearch() {
		const {pathname} = this.props.location;
		if (pathname === "/") return true;
		return (
			HIDE_SEARCH_ON_PATHS.find(path => pathname.startsWith(path)) !== undefined
		);
	}

	componentDidMount() {
		this.props.getAccount();
	}

	componentDidUpdate(prevProps) {
		const {pathname} = this.props.location;
		if (prevProps.location.pathname !== pathname) {
			this.setState({isOpen: false});
		}
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}

	renderAccountNavItem() {
		const {loggedIn, profileImage} = this.props;
		if (loggedIn === null) {
			return null;
		} else if (!loggedIn) {
			return (
				<NavItem
					key="logIn"
					onClick={() =>
						this.setState({
							isOpen: false,
						})
					}
				>
					<NavLink tag={Link} to="/login">
						Log In
					</NavLink>
				</NavItem>
			);
		}

		let dropdownItems = ACCOUNT_TABS.map(({name, link}) => (
			<DropdownItem key={name} tag={Link} to={link}>
				{name}
			</DropdownItem>
		));
		return (
			<Fragment>
				<NavItem className="mobile">
					<NavLink tag={Link} to="/account/books">
						My Account
					</NavLink>
				</NavItem>
				<UncontrolledDropdown nav inNavbar className="desktop">
					<DropdownToggle nav className="dropdown-toggle-btn">
						{profileImage === null ? null : (
							<CoverImage
								className="nav-account-img"
								src={profileImage}
								style={{borderRadius: "50%"}}
							/>
						)}
						My Account
						<FontAwesomeIcon
							className="nav-account-chevron"
							icon={["far", "chevron-down"]}
						/>
					</DropdownToggle>
					<DropdownMenu right>
						{dropdownItems}
						<DropdownItem divider />
						<DropdownItem tag={Link} to="/logout">
							Log Out
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</Fragment>
		);
	}

	render() {
		const bars = ["top", "middle", "bottom"].map(position => (
			<span
				key={position}
				className={`icon-bar ${position}-bar ${
					this.state.isOpen ? "collapsed" : ""
				}`}
			/>
		));

		const navItems = LINKS.map(({page, link}) => (
			<NavItem
				key={page}
				className="d-md-none d-xl-inline-block"
				onClick={() =>
					this.setState({
						isOpen: false,
					})
				}
			>
				<NavLink tag={Link} to={link}>
					{page}
				</NavLink>
			</NavItem>
		));

		return (
			<div>
				<ReactstrapNavbar expand="md">
					<NavbarBrand tag={Link} to="/">
						<Logo className="nav-logo" />
						How Long to Read
					</NavbarBrand>
					<NavbarToggler onClick={this.toggle}>{bars}</NavbarToggler>
					<Collapse id="navCollapse" isOpen={this.state.isOpen} navbar>
						<Nav id="nav-content" navbar>
							{this.shouldHideSearch() ? null : (
								<SearchForm form="nav" isSmall={true} />
							)}
							{navItems}
							{this.renderAccountNavItem()}
						</Nav>
					</Collapse>
				</ReactstrapNavbar>
			</div>
		);
	}
}

Navbar.propTypes = {
	loggedIn: PropTypes.bool,
	profileImage: PropTypes.string,
	getAccount: PropTypes.func.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}),
};

const mapStateToProps = state => {
	return {
		loggedIn: state.account.loggedIn,
		profileImage: state.account.profileImage,
	};
};

export default connect(mapStateToProps, {getAccount})(Navbar);
