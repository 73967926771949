import React from "react";
import url from "url";

const BooksMirrored = props => (
	<img
		alt="Stack of books mirrored"
		src={url.resolve(process.env.PUBLIC_URL, "/img/books-mirrored.svg")}
		{...props}
	/>
);

export default BooksMirrored;
