import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import {bookDetailsType, shelfType} from "../types";
import {getShelves, addBookToShelf, deleteBookFromShelf} from "../actions";

class AddToShelfDropdown extends Component {
	state = {
		dropdownOpen: false,
		buttonText: "Add to shelf",
	};
	constructor(props) {
		super(props);
		this.handleDropdown = this.handleDropdown.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.renderItem = this.renderItem.bind(this);
	}

	componentDidMount() {
		const {loggedIn, shelves} = this.props;
		if (loggedIn && shelves === null) {
			this.props.getShelves({pageNumber: 1});
		}
	}

	handleDropdown() {
		this.setState({dropdownOpen: !this.state.dropdownOpen});
	}

	handleClick(e, shelfName) {
		e.preventDefault();
		const {formattedName, books} = this.props.shelves.find(s => s.name === shelfName);
		const {book} = this.props;
		const inShelf = !!books.find(b => b.id === book.id);
		const params = {shelfId: shelfName, bookId: book.id};
		if (inShelf) {
			this.setState({buttonText: `Removed from ${formattedName}`});
			this.props.deleteBookFromShelf(params);
		} else {
			this.setState({buttonText: `Added to ${formattedName}`});
			this.props.addBookToShelf(params);
		}
	}

	renderItem(shelf) {
		const {formattedName, name, books} = shelf;
		const {book} = this.props;
		const activeClass = books.find(b => b.id === book.id) ? "active" : "";
		return (
			<Fragment key={name}>
				<DropdownItem
					onClick={e => this.handleClick(e, name)}
					className={activeClass}
					toggle={false}
				>
					{formattedName}
				</DropdownItem>
				<DropdownItem divider />
			</Fragment>
		);
	}

	render() {
		const {dropdownOpen, buttonText} = this.state;
		const {shelves} = this.props;
		return (
			<Dropdown
				isOpen={dropdownOpen}
				toggle={this.handleDropdown}
				className="shelf-dropdown"
			>
				<DropdownToggle color="primary">
					<span className="button-text">{buttonText}</span>
					<FontAwesomeIcon
						icon={["far", dropdownOpen ? "chevron-up" : "chevron-down"]}
					/>
				</DropdownToggle>
				<DropdownMenu>
					{shelves === null ? null : shelves.map(this.renderItem)}
					<Link to="/account/books/new">
						<DropdownItem>
							<FontAwesomeIcon icon={["far", "plus"]} id="create-shelf-icon" />
							Create Shelf
						</DropdownItem>
					</Link>
				</DropdownMenu>
			</Dropdown>
		);
	}
}

AddToShelfDropdown.propTypes = {
	loggedIn: PropTypes.bool,
	shelves: PropTypes.arrayOf(PropTypes.shape(shelfType)),
	book: bookDetailsType,
	getShelves: PropTypes.func.isRequired,
	addBookToShelf: PropTypes.func.isRequired,
	deleteBookFromShelf: PropTypes.func.isRequired,
};

const mapStateToProps = ({account: {loggedIn, shelves}, book: {details}}) => ({
	loggedIn,
	shelves,
	book: details,
});

export default connect(mapStateToProps, {
	getShelves,
	addBookToShelf,
	deleteBookFromShelf,
})(AddToShelfDropdown);
