import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Form, FormFeedback, Row} from "reactstrap";
import PropTypes from "prop-types";
import v from "validator";

import {formType} from "../types";
import {signUp} from "../actions";
import history from "../history";
import Input from "../components/lib/Input";
import Button from "../components/lib/Button";

class SignupForm extends Component {
	componentDidMount() {
		if (this.props.loggedIn) {
			history.push("/account/settings");
		}
	}
	onSubmit = formValues => {
		return this.props.signUp(formValues);
	};
	responseMessage(submitSucceeded, error) {
		if (submitSucceeded) {
			setTimeout(() => history.push("/login"), 2000);
			return (
				<FormFeedback valid={true} style={{display: "block"}}>
					Your account was created successfully. Redirecting you to login...
				</FormFeedback>
			);
		}
		return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
	}
	render() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
		} = this.props;
		return (
			<Form onSubmit={handleSubmit(this.props.signUp)} autoComplete="off">
				<Field
					id="firstName"
					name="firstName"
					component={Input}
					placeholder="First name"
					label="First Name"
					autoComplete="first-name"
				/>
				<Field
					id="lastName"
					name="lastName"
					component={Input}
					placeholder="Last name"
					label="Last Name"
					autoComplete="last-name"
				/>
				<Field
					id="username"
					name="username"
					component={Input}
					placeholder="Username"
					label="Username"
				/>
				<Field
					id="email"
					name="email"
					component={Input}
					placeholder="Email"
					label="Email"
					autoComplete="email"
				/>
				<Field
					id="password"
					name="password"
					component={Input}
					placeholder="Password"
					type="password"
					label="Password"
					autoComplete="password"
				/>
				<Row id="checkbox-row">
					<p id="checkbox-text">
						I agree to the{" "}
						<a target="_blank" href="/disclaimer" className="secondary">
							terms and conditions
						</a>
					</p>
					<Field
						id="consent"
						name="consent"
						component={Input}
						type="checkbox"
					/>
				</Row>
				<Button
					id="submit"
					name="submit"
					disabled={submitting || submitSucceeded}
				>
					Sign Up
				</Button>
				{!(submitSucceeded || submitFailed)
					? null
					: this.responseMessage(submitSucceeded, error)}
			</Form>
		);
	}
}

SignupForm.propTypes = {
	...formType,
	loggedIn: PropTypes.bool,
	signUp: PropTypes.func.isRequired,
};

const validate = formValues => {
	const {email = "", password = "", firstName = "", consent} = formValues;
	let errors = {};
	if (!v.isEmail(email)) {
		errors.email = "Please enter a valid email";
	}
	if (password.length < 8) {
		errors.password = "Your password must be at least 8 characters long";
	}
	if (firstName === "") {
		errors.firstName = "Please enter your name";
	}
	if (!consent) {
		errors.consent = "You must agree to the terms and conditions";
	}
	return errors;
};

const formWrapped = reduxForm({
	form: "SignupForm",
	validate,
})(SignupForm);

function mapStateToProps({account: {loggedIn}}) {
	return {loggedIn};
}

export default connect(mapStateToProps, {signUp})(formWrapped);
