import React, {Component, Fragment} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {Container, Row, Col} from "reactstrap";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import {addPageHead} from "../../config";
import {bookType, readingTestType, shelfType} from "../../types";
import {getReadingSpeedTests, recommendedBooks} from "../../actions";
import {MY_BOOKS} from "./types";
import AccountWrapper from "../../components/AccountWrapper";
import BookCarousel from "../../components/BookCarousel";
import Button from "../../components/lib/Button";
import NewShelfButton from "../../components/NewShelfButton";
import Spinner from "../../components/lib/Spinner";
import TestCollection from "../../components/TestCollection";

class MyBooks extends Component {
	componentDidMount() {
		if (this.props.tests === null) {
			this.props.getReadingSpeedTests();
		}
		if (this.props.recommended === null) {
			this.props.getRecommendedBooks();
		}
	}
	renderShelf(shelf) {
		return (
			<Col key={shelf.name}>
				<Row className="shelf-row">
					<BookCarousel {...shelf} />
				</Row>
				<Row className="view-more-row justify-content-center">
					<Link to={`/account/books/${shelf.name}`}>
						<Button className="view-more btn-small" color="primary">
							View more
						</Button>
					</Link>
				</Row>
			</Col>
		);
	}

	renderTests() {
		const tests = _.get(this.props, "tests[0].tests", []);
		if (tests.length === 0) {
			return null;
		}
		return (
			<Fragment>
				<p className="subtitle">
					Try some of the following reading speed tests!
				</p>
				<Row className="shelves-row">
					<TestCollection items={tests} className="shelves-row" />
				</Row>
			</Fragment>
		);
	}

	renderContent() {
		const {hasGoodreadsAccount, shelves, recommended} = this.props;
		// if account not loaded or has goodreads account and shelves not loaded
		if (
			hasGoodreadsAccount === null
			|| (hasGoodreadsAccount === true && shelves === null)
		) {
			return <Spinner />;
		}
		if (hasGoodreadsAccount === false || shelves.length === 0) {
			// intro page
			return (
				<Fragment>
					<p className="h1">Welcome to How Long to Read!</p>
					<p id="bookshelf-subtitle">
						Create your first bookshelf to start tracking your times:
					</p>
					<Row className="justify-content-center shelves-row">
						<NewShelfButton className="m-auto" />
					</Row>

					{this.renderTests()}

					<p className="subtitle">Or, check out some books we recommend!</p>
					<Row className="shelves-row">
						<BookCarousel books={recommended} />
					</Row>
				</Fragment>
			);
		}
		return <div id="shelves-wrapper">{shelves.map(this.renderShelf)}</div>;
	}
	render() {
		return (
			<AccountWrapper page={MY_BOOKS}>
				{addPageHead("My Books")}

				<Row className="align-items-center m-0 desktop">
					<h1 className="content-title">My Books</h1>
					<NewShelfButton className="ml-auto" />
				</Row>
				<Container className="wrapper">{this.renderContent()}</Container>
			</AccountWrapper>
		);
	}
}

MyBooks.propTypes = {
	getReadingSpeedTests: PropTypes.func.isRequired,
	getRecommendedBooks: PropTypes.func.isRequired,
	hasGoodreadsAccount: PropTypes.bool,
	recommended: PropTypes.arrayOf(PropTypes.shape(bookType)),
	shelves: PropTypes.arrayOf(PropTypes.shape(shelfType)),
	tests: PropTypes.arrayOf(PropTypes.shape(readingTestType)),
};

function mapStateToProps({account, readingTest: {tests}, book: {recommended}}) {
	const {shelves, goodreadsId} = account;
	const hasGoodreadsAccount = goodreadsId === null ? null : goodreadsId > 0;
	return {shelves, hasGoodreadsAccount, tests, recommended};
}

export default connect(mapStateToProps, {
	getReadingSpeedTests,
	getRecommendedBooks: recommendedBooks,
})(MyBooks);
