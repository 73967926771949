import React, {Component, Fragment} from "react";
import {
	Container,
	Dropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from "reactstrap";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import url from "url";

import "../scss/Account.scss";
import {shelfType} from "../types";
import {getShelves, getAccount, sendVerificationEmail} from "../actions";
import {TABS, MY_BOOKS, LOGOUT} from "../pages/account/types";
import Alert from "./lib/Alert";

const BookStack = props => (
	<img
		alt="Stack of books"
		src={url.resolve(process.env.PUBLIC_URL, "/img/books-balloon.svg")}
		{...props}
	/>
);

class AccountWrapper extends Component {
	state = {
		dropdownOpen: false,
		verificationEmailSent: false,
	};
	constructor(props) {
		super(props);
		this.handleDropdown = this.handleDropdown.bind(this);
		this.renderMenuDropdown = this.renderMenuDropdown.bind(this);
		this.renderTab = this.renderTab.bind(this);
		this.renderTabMobile = this.renderTabMobile.bind(this);
		this.handleEmailConfirmationClick = this.handleEmailConfirmationClick.bind(this);
	}
	componentDidMount() {
		if (this.props.loggedIn) {
			this.props.getAccount();
			this.props.getShelves({pageNumber: 1});
		}
	}
	renderTab({type, link, name}) {
		const {page: activePage, shelves, activeShelf = null} = this.props;
		let inner = null;
		if (type === MY_BOOKS && shelves !== null) {
			inner = (
				<ul id="sublist">
					{shelves.map(({name, formattedName}) => (
						<li key={name}>
							<Link
								to={`/account/books/${name}`}
								className={name === activeShelf ? "sub-active" : ""}
							>
								{formattedName}
							</Link>
						</li>
					))}
				</ul>
			);
		}
		return (
			<li key={type}>
				<Link to={link} className={type === activePage ? "main-active" : ""}>
					{name}
				</Link>
				{inner}
			</li>
		);
	}

	handleDropdown() {
		this.setState({dropdownOpen: !this.state.dropdownOpen});
	}

	renderTabMobile({type, name, link}) {
		const {page: activePage} = this.props;
		return (
			<Fragment key={type}>
				<DropdownItem divider />
				<Link to={link}>
					<DropdownItem className={type === activePage ? "active" : ""}>
						{name}
					</DropdownItem>
				</Link>
			</Fragment>
		);
	}

	renderMenuDropdown() {
		const {page: activePage, shelves, activeShelf = null} = this.props;
		const myBooksTab = TABS.find(t => t.type === MY_BOOKS);
		const activePageName = TABS.find(t => t.type === activePage).name;
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.handleDropdown}>
				<DropdownToggle id="account-nav-toggle" className="btn-small">
					{activePageName}
					<div id="caret-wrapper">
						<FontAwesomeIcon icon={["far", "chevron-down"]} />
					</div>
				</DropdownToggle>
				<DropdownMenu>
					<Link to={myBooksTab.link}>
						<DropdownItem
							className={
								activeShelf === null && myBooksTab.type === activePage
									? "active"
									: ""
							}
						>
							{myBooksTab.name}
						</DropdownItem>
					</Link>
					{shelves !== null
						&& shelves.map(({name, formattedName}) => (
							<Link key={name} to={`/account/books/${name}`}>
								<DropdownItem
									className={`nested-dropdown ${
										name === activeShelf ? "active" : ""
									}`}
								>
									{formattedName}
								</DropdownItem>
							</Link>
						))}
					{TABS.filter(t => t.type !== MY_BOOKS).map(this.renderTabMobile)}
					{this.renderTabMobile({
						type: LOGOUT,
						name: "Log Out",
						link: "/logout",
					})}
				</DropdownMenu>
			</Dropdown>
		);
	}

	handleEmailConfirmationClick() {
		if (!this.state.verificationEmailSent) {
			this.setState({verificationEmailSent: true});
			this.props.sendVerificationEmail();
		}
	}

	renderEmailConfirmationAlert() {
		if (this.props.emailConfirmed !== false) return null;
		const text = this.state.verificationEmailSent
			? "Verification email sent!"
			: "Click here to resend verification email";
		return (
			<Alert color="warning">
				Your email has not been confirmed yet! Please check your inbox for an
				email from us.
				<hr />
				<small
					className="small-link"
					onClick={this.handleEmailConfirmationClick}
				>
					{text}
				</small>
			</Alert>
		);
	}

	render() {
		if (this.props.loggedIn === false) {
			return <Redirect to="/login" />;
		}
		return (
			<div className="main account d-flex">
				{/* mobile only */}
				<span id="account-wrapper-bg" className="mobile"></span>
				<BookStack id="m-book-stack" className="mobile" />
				<section className="account-nav mobile">
					<h2>My Account</h2>
					{this.renderMenuDropdown()}
				</section>

				{/* desktop only */}
				<span id="bottom-slant" className="desktop" />
				<span id="bottom-slant-2" className="desktop" />
				<BookStack id="book-stack" className="desktop" />

				<section className="sidebar desktop">
					<Container>
						<h2>My Account</h2>
						<ul id="mainlist">{TABS.map(this.renderTab)}</ul>
					</Container>
				</section>

				{/* shared */}
				<section className="content">
					{this.renderEmailConfirmationAlert()}
					{this.props.children}
				</section>
			</div>
		);
	}
}

AccountWrapper.propTypes = {
	loggedIn: PropTypes.bool,
	shelves: PropTypes.arrayOf(PropTypes.shape(shelfType)),
	emailConfirmed: PropTypes.bool,
	getAccount: PropTypes.func.isRequired,
	getShelves: PropTypes.func.isRequired,
	sendVerificationEmail: PropTypes.func.isRequired,
	page: PropTypes.string.isRequired,
	activeShelf: PropTypes.string,
	children: PropTypes.arrayOf(PropTypes.element),
};

const mapStateToProps = ({account: {loggedIn, shelves, emailConfirmed}}) => ({
	loggedIn,
	shelves,
	emailConfirmed,
});

export default connect(mapStateToProps, {
	getAccount,
	getShelves,
	sendVerificationEmail,
})(AccountWrapper);
