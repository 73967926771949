import React from "react";
import url from "url";

const ManReading = props => (
	<img
		alt="Man sitting and reading"
		src={url.resolve(process.env.PUBLIC_URL, "/img/man-reading.svg")}
		{...props}
	/>
);
export default ManReading;
