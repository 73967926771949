import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import OutboundLink from "./OutboundLink";

import Button from "./lib/Button";

const AudibleButton = props => {
	const {title} = props;
	const urlObj = new URL("https://www.amazon.com/audibleplus?ref_=assoc_tag_ph_1524216631897&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&tag=hltr-20&linkId=73a56fbc1ddd26ad3c9c58e3c3ca8b84");
	urlObj.searchParams.set("k", title);
	return (
		<OutboundLink
			to={urlObj.toString()}
			target="_blank"
			rel="noopener noreferrer"
			eventLabel="audibleButton"
		>
			<Button {...props} color="primary">
				<FontAwesomeIcon icon={["fab", "audible"]} />
				Listen to the audiobook on Audible!
			</Button>
		</OutboundLink>
	);
};

AudibleButton.propTypes = {
	title: PropTypes.string,
};

export default AudibleButton;
