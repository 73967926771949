import PropTypes from "prop-types";

export const bookType = {
	averageReadingTime: PropTypes.number.required,
	personalizedReadingTime: PropTypes.number,
	author: PropTypes.string.required,
	id: PropTypes.number.required,
	image: PropTypes.string,
	smallImage: PropTypes.string,
	isbn: PropTypes.number,
	title: PropTypes.string.required,
};

export const bookDetailsType = PropTypes.shape({
	audioBook: PropTypes.shape({
		duration: PropTypes.number.isRequired,
		link: PropTypes.string.isRequired,
	}),
	author: PropTypes.string.isRequired,
	averageReadingTime: PropTypes.number.isRequired,
	description: PropTypes.string,
	image: PropTypes.string,
	id: PropTypes.number.isRequired,
	isbn: PropTypes.number,
	links: PropTypes.shape({
		amazonLink: PropTypes.string,
		audibleLink: PropTypes.string,
		goodReadsLink: PropTypes.string,
	}),
	samplePassage: PropTypes.string,
	title: PropTypes.string,
	wordCount: PropTypes.shape({
		value: PropTypes.number,
		verified: PropTypes.bool.isRequired,
	}).isRequired,
});

export const bookDetailsErrorType = PropTypes.shape({
	statusCode: PropTypes.number.isRequired,
	message: PropTypes.string.isRequired,
});

export const readingTestType = {
	estimatedReadingMs: PropTypes.number,
	id: PropTypes.number,
	image: PropTypes.string,
	name: PropTypes.string,
	text: PropTypes.string, // lazy loaded
	wordCount: PropTypes.number,
};

export const shelfType = {
	books: PropTypes.arrayOf(PropTypes.shape(bookType)).isRequired,
	name: PropTypes.string.isRequired,
	formattedName: PropTypes.string.isRequired,
	totalAverageReadingTime: PropTypes.number.isRequired,
	totalPersonalizedReadingTime: PropTypes.number,
};

export const formType = {
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	submitFailed: PropTypes.bool.isRequired,
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
};

export const itemType = {
	id: PropTypes.number.isRequired,
	title: PropTypes.string,
	author: PropTypes.string,
	image: PropTypes.string,
	link: PropTypes.string,
	size: PropTypes.oneOf(["sm", "lg"]),
	selected: PropTypes.bool,
	openInNew: PropTypes.bool,
	onClick: PropTypes.func,
};
