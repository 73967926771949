import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Form, FormFeedback, Row, Col} from "reactstrap";
import PropTypes from "prop-types";

import {formType} from "../types";
import {logIn, getAccount} from "../actions";
import Button from "../components/lib/Button";
import GoodreadsButton from "../components/GoodreadsButton";
import history from "../history";
import Input from "../components/lib/Input";

class LoginForm extends Component {
	componentDidMount() {
		this.props.getAccount();
	}
	componentDidUpdate() {
		if (this.props.loggedIn) {
			history.push("/account/books");
		}
	}
	onSubmit = formValues => {
		return this.props.logIn(formValues);
	};
	responseMessage(submitSucceeded, error) {
		if (!submitSucceeded) {
			return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
		}
	}
	render() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
		} = this.props;
		return (
			<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
				<Field
					id="email"
					name="email"
					component={Input}
					placeholder="Email"
					label="Email"
					autoComplete="username"
				/>
				<Field
					id="password"
					name="password"
					component={Input}
					placeholder="Password"
					type="password"
					label="Password"
					autoComplete="current-password"
				/>
				<Row className="login-btns">
					<Col md={6}>
						<Button
							id="submit"
							name="submit"
							disabled={submitting || submitSucceeded}
							color="primary"
						>
							Log In
						</Button>
					</Col>
					<Col md={6}>
						<GoodreadsButton />
					</Col>
				</Row>
				{!(submitSucceeded || submitFailed)
					? null
					: this.responseMessage(submitSucceeded, error)}
			</Form>
		);
	}
}

LoginForm.propTypes = {
	...formType,
	loggedIn: PropTypes.bool,
	email: PropTypes.string,
	initialValues: PropTypes.shape({email: PropTypes.string}).isRequired,
	getAccount: PropTypes.func.isRequired,
	logIn: PropTypes.func.isRequired,
};

const formWrapped = reduxForm({
	form: "loginForm",
})(LoginForm);

function mapStateToProps({account: {loggedIn, email}}) {
	return {loggedIn, initialValues: {email}};
}

export default connect(mapStateToProps, {logIn, getAccount})(formWrapped);
