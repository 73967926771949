import React, {Component} from "react";
import {connect} from "react-redux";
import {Container} from "reactstrap";
import PropTypes from "prop-types";

import "../scss/Home.scss";
import "../scss/Search.scss";
import {popularAndRecommendedBooks} from "../actions";
import {addPageHead} from "../config";
import SearchForm from "../components/SearchForm";
import BookCarousel from "../components/BookCarousel";
import FeaturedLogos from "../components/FeaturedLogos";
import {bookType} from "../types";
import TopBookshelf from "../components/img/TopBookshelf";
import BottomBookshelf from "../components/img/BottomBookshelf";

class Home extends Component {
	componentDidMount() {
		const {
			getPopularAndRecommendedBooks,
			popularBooks,
			recommendedBooks,
		} = this.props;
		if (popularBooks !== null && recommendedBooks !== null) return;
		getPopularAndRecommendedBooks();
	}

	render() {
		const {popularBooks, recommendedBooks} = this.props;
		return (
			<div className="main">
				{addPageHead(
					"How Long to Read - Search Reading Times for Millions of Books",
					"Search millions of books and find reading times for each one on How Long to Read. Calculate your personalized reading speed by reading a sample passage.",
					true,
				)}
				<section className="search">
					<TopBookshelf className="bookshelf bookshelf-top" />
					<Container className="search-content">
						<h1>Find reading times for millions of books!</h1>
						<SearchForm form="home" />
					</Container>
					<BottomBookshelf className="bookshelf bookshelf-bottom" />
				</section>

				<div className="search-content-wrapper">
					<span id="accent" />
					<Container className="search-inner-wrapper d-flex flex-column justify-content-center align-items-middle">
						<section className="featured-by-logos">
							<FeaturedLogos fillClass={"logos-gray"} maxLogos={5} />
						</section>

						<section className="popular-books">
							<BookCarousel name="Popular Books" books={popularBooks} />
						</section>

						<section className="recommended-books">
							<BookCarousel name="Recommended Books" books={recommendedBooks} />
						</section>
					</Container>
				</div>
			</div>
		);
	}
}

Home.propTypes = {
	popularBooks: PropTypes.arrayOf(PropTypes.shape(bookType)),
	recommendedBooks: PropTypes.arrayOf(PropTypes.shape(bookType)),
	getPopularAndRecommendedBooks: PropTypes.func.isRequired,
};

function mapStateToProps({book}) {
	return {popularBooks: book.popular, recommendedBooks: book.recommended};
}

export default connect(mapStateToProps, {
	getPopularAndRecommendedBooks: popularAndRecommendedBooks,
})(Home);
