import React, {Component} from "react";
import {connect} from "react-redux";
import {Container, Row} from "reactstrap";
import PropTypes from "prop-types";

import "../../scss/Account.scss";
import {addPageHead} from "../../config";
import {getReadingSpeedTests} from "../../actions";
import {READING_SPEED} from "./types";
import {readingTestType} from "../../types";
import AccountWrapper from "../../components/AccountWrapper";
import ReadingSpeedDisplay from "../../components/ReadingSpeedDisplay";
import Spinner from "../../components/lib/Spinner";
import TestCollection from "../../components/TestCollection";

class ReadingTests extends Component {
	componentDidMount() {
		this.props.getReadingSpeedTests();
	}

	renderTestCategory = (testCategory, i) => {
		const {category, tests} = testCategory;
		return (
			<Container key={i}>
				<Row>
					<h3>{category}</h3>
				</Row>
				<TestCollection items={tests} />
			</Container>
		);
	};

	render() {
		const {tests: testCategories} = this.props;
		return (
			<AccountWrapper page={READING_SPEED}>
				{addPageHead("Reading Speed")}
				<h1 className="content-title desktop">Reading Speed Tests</h1>
				<Container className="wrapper">
					<ReadingSpeedDisplay />

					<h2>Tests</h2>
					<p>
						These are a collection of preselected passages that range in
						difficulties and genres. You can read a wide variety of these to get
						a more accurate calculation of your reading speed.
					</p>
					{testCategories === null ? (
						<Spinner />
					) : (
						testCategories.map(this.renderTestCategory)
					)}
				</Container>
			</AccountWrapper>
		);
	}
}

ReadingTests.propTypes = {
	tests: PropTypes.arrayOf(PropTypes.shape(readingTestType)),
	getReadingSpeedTests: PropTypes.func.isRequired,
};

function mapStateToProps({readingTest: {tests}}) {
	return {tests};
}

export default connect(mapStateToProps, {getReadingSpeedTests})(ReadingTests);
