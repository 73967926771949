import React from "react";
import PropTypes from "prop-types";
import {Route} from "react-router-dom";

import Error from "../pages/Error";

export default function WithRole({component: Component, ...rest}) {
	const role = localStorage.getItem("role");

	return (
		<Route
			{...rest}
			render={props =>
				role === "admin" ? <Component {...props} /> : <Error />
			}
		/>
	);
}

WithRole.propTypes = {
	component: PropTypes.element.isRequired,
};
