import React from "react";
import {
	FormFeedback,
	FormGroup,
	FormText,
	Input as ReactstrapInput,
	Label,
} from "reactstrap";
import PropTypes from "prop-types";

const Input = props => {
	const {
		helpText = null,
		input = {},
		label = null,
		meta,
		placeholder = null,
		rows = null,
		type = null,
		autoComplete,
	} = props;
	const {
		error: errorText = null,
		invalid,
		touched,
		valid,
		warning: validText = null,
	} = meta;
	const validation = {
		invalid: touched && invalid ? true : null,
		valid: touched && valid && validText !== null ? true : null,
	};

	//return form group
	return (
		<FormGroup>
			{label === null ? null : <Label for={input.name}>{label}</Label>}
			<ReactstrapInput
				placeholder={placeholder}
				type={type}
				{...input}
				{...validation}
				rows={type === "textarea" && rows !== null ? rows : null}
				autoComplete={autoComplete}
			/>
			{errorText === null && validText === null ? null : (
				<FormFeedback valid={validation.valid}>
					{errorText || validText}
				</FormFeedback>
			)}
			{helpText === null ? null : <FormText>{helpText}</FormText>}
		</FormGroup>
	);
};

Input.defaultProps = {
	helpText: null,
	input: {},
	label: null,
	placeholder: null,
	rows: null,
	type: null,
};

Input.propTypes = {
	helpText: PropTypes.string,
	input: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
	label: PropTypes.string,
	meta: PropTypes.shape({
		error: PropTypes.string,
		invalid: PropTypes.bool.isRequired,
		touched: PropTypes.bool.isRequired,
		valid: PropTypes.bool.isRequired,
		warning: PropTypes.string,
	}).isRequired,
	placeholder: PropTypes.string,
	rows: PropTypes.number,
	type: PropTypes.string,
	autoComplete: PropTypes.string,
};

export default Input;
