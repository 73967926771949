import React, {Component} from "react";
import {useLocation} from "react-router-dom";
import * as analytics from "../config";
import PropTypes from "prop-types";

class AnalyticsComponent extends Component {
	componentDidMount() {
		analytics.init();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location !== prevProps.location) {
			const path = this.props.location.pathname + this.props.location.search;

			const bookIdPattern = /^\/books\/\d+$/;
			// Only send page view if the path does not match the books/id pattern
			if (!bookIdPattern.test(path)) {
				analytics.sendPageview(path);
			}
		}
	}
	render() {
		return null;
	}
}

const AnalyticsWrapper = () => {
	const location = useLocation();
	return <AnalyticsComponent location={location} />;
};

AnalyticsComponent.propTypes = {
	location: PropTypes.object.isRequired,
};

export default AnalyticsWrapper;
