import React, {Component} from "react";
import {Row, Col} from "reactstrap";
import url from "url";

import "../scss/About.scss";
import {addPageHead} from "../config";
import CoverImage from "../components/CoverImage";
import FeaturedLogos from "../components/FeaturedLogos";
import InfoPage from "../components/InfoPage";

const TEAM = [
	{
		name: "Harry Tong",
		image: url.resolve(process.env.PUBLIC_URL, "/img/harry.jpeg"),
	},
	{
		name: "Alex Thorburn-Winsor",
		image: url.resolve(process.env.PUBLIC_URL, "img/profile.png"),
	},
];

class About extends Component {
	renderMemberIcon({name, image}) {
		return (
			<Col
				key={name}
				className="team-member d-flex flex-column align-items-center"
			>
				<CoverImage
					className="team-member-img"
					src={image}
					style={{borderRadius: "50%"}}
				/>
				<h3>{name}</h3>
			</Col>
		);
	}

	render() {
		return (
			<InfoPage>
				{addPageHead("About")}
				<Row>
					<Col md={{size: 7, offset: 1}} className="description">
						<h1>About Us</h1>
						<p>
							How Long to Read is a book search engine that helps you find out
							how long it will take to read books and provide reading time data
							that is tailored to you. With our simple WPM (words per minute)
							test you can find out how long it will take you to read almost
							anything, and also use our search engine to find books that will
							fit the time you have to read.
						</p>
						<p>
							How Long to Read has been featured in The Economist, the New York
							Times, Lifehacker.com, Yahoo! News, The Independent and other
							websites and blogs.
						</p>
					</Col>
					<Col md={{size: 3}}>{TEAM.map(this.renderMemberIcon)}</Col>
				</Row>
				<Row>
					<section className="featured-by-logos">
						<FeaturedLogos fillClass="logos-primary" showLinks />
					</section>
				</Row>
			</InfoPage>
		);
	}
}

export default About;
