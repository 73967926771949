import React, {Component} from "react";
import {addPageHead} from "../config";
import "../scss/Error.scss";
import ErrorWrapper from "../components/ErrorWrapper";

export default class Error extends Component {
	render() {
		const params = new URLSearchParams(window.location.search.slice(1));
		const status = params.get("status") || 404;
		const message = params.get("_error");

		return (
			<ErrorWrapper>
				{addPageHead("Error")}
				<h1>Woah... that wasn&apos;t supposed to happen.</h1>
				<h5 className="error-code-message">
					{`Error ${status}: ${message
						|| "We couldn't find the page you were looking for. "}`}{" "}
					&#128533;{" "}
				</h5>
			</ErrorWrapper>
		);
	}
}
