import React, {Component} from "react";
import {connect} from "react-redux";
import {Container} from "reactstrap";
import PropTypes from "prop-types";

import "../scss/Search.scss";
import {searchBooks} from "../actions";
import {addPageHead} from "../config";
import SearchForm from "../components/SearchForm";
import BookCollection from "../components/BookCollection";
import {bookType} from "../types";
import WomanMap from "../components/img/WomanMap";

class SearchResults extends Component {
	componentDidMount() {
		const {
			match: {
				params: {qs},
			},
		} = this.props;
		if (this.props.searchResults === null) {
			this.props.searchBooks(qs);
		}
	}
	render() {
		const {
			searchResults,
			match: {
				params: {qs},
			},
		} = this.props;
		return (
			<div className="main">
				{addPageHead("Results")}
				<section className="search search-results">
					<WomanMap id="woman-map" />
					<Container className="search-content">
						<h2>Here&apos;s what we found!</h2>
						<SearchForm form="results" initialValues={{search: qs}} />
					</Container>
				</section>

				<div className="search-content-wrapper">
					<span id="accent" />

					<Container className="search-inner-wrapper d-flex flex-column justify-content-center align-items-middle">
						<section className="search-result-books">
							<BookCollection items={searchResults} showReadingTime={false} />
						</section>
					</Container>
				</div>
			</div>
		);
	}
}

SearchResults.propTypes = {
	searchResults: PropTypes.arrayOf(PropTypes.shape(bookType)),
	searchBooks: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			qs: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

function mapStateToProps({book: {searchResults}}) {
	return {searchResults};
}

export default connect(mapStateToProps, {searchBooks})(SearchResults);
