import React from "react";
import PropTypes from "prop-types";

const OutboundLink = ({to, children}) => {
	const handleClick = () => {
		// Outbound links are handled by Google Analytics by default
		// TODO: Delete this when we verify it is working
		// ReactGA.event({
		//   category: "Outbound Link",
		//   action: "Click",
		//   label: to,
		// });
	};

	return (
		<a
			href={to}
			onClick={handleClick}
			target="_blank"
			rel="noopener noreferrer"
		>
			{children}
		</a>
	);
};

OutboundLink.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default OutboundLink;
