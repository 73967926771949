import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {getReadingSpeed} from "../actions";

class ReadingSpeedDisplay extends Component {
	componentDidMount() {
		this.props.getReadingSpeed();
	}

	render() {
		const {readingSpeed} = this.props;
		if (readingSpeed === null || readingSpeed === 0) {
			return null;
		}
		return (
			<div className="reading-speed-display">
				<h2>Your Average Speed</h2>
				<p id="user-wpm">
					<b>{readingSpeed} WPM</b>
				</p>
				<p>
					This speed is calculated based on the average speeds of your previous
					reading test results. If a data point looks really off to us, we
					won&apos;t include it in your calculation.
				</p>
			</div>
		);
	}
}

ReadingSpeedDisplay.propTypes = {
	readingSpeed: PropTypes.number,
	getReadingSpeed: PropTypes.func.isRequired,
};

function mapStateToProps({account: {readingSpeed}}) {
	return {readingSpeed};
}

export default connect(mapStateToProps, {getReadingSpeed})(ReadingSpeedDisplay);
