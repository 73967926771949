import React, {Component} from "react";
import PropTypes from "prop-types";

const withScreenWidth = WrappedComponent => {
	return class WithScreenWidth extends Component {
		constructor(props) {
			super(props);
			this.state = {
				screenWidth: window.innerWidth,
			};
		}

		componentDidMount() {
			window.addEventListener("resize", this.handleResize);
		}

		componentWillUnmount() {
			window.removeEventListener("resize", this.handleResize);
		}

		handleResize = () => {
			this.setState({
				screenWidth: window.innerWidth,
			});
		};

		render() {
			return (
				<WrappedComponent
					screenWidth={this.state.screenWidth}
					{...this.props}
				/>
			);
		}
	};
};

export default withScreenWidth;

withScreenWidth.propTypes = {
	WrappedComponent: PropTypes.element.isRequired,
};
