import React from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {addPageHead} from "../config";
import {createReport} from "../actions";
import Button from "../components/lib/Button";
import {reduxForm} from "redux-form";
import {useParams} from "react-router-dom";
import "../scss/Error.scss";
import ErrorWrapper from "../components/ErrorWrapper";

const DEFAULT_INVALID_BOOK_DESCRIPTION = "book page broken";

const BookDetailsError = props => {
	const {handleSubmit, submitting} = props;
	const {bookId} = useParams();
	const dispatch = useDispatch();
	const {reportBookResult} = useSelector(state => state.book);

	const {email, loggedIn} = useSelector(state => state.account);
	const reportInvalidBook = async () => {
		const payload = {
			bookId: parseInt(bookId),
			email: loggedIn ? email : null,
			description: DEFAULT_INVALID_BOOK_DESCRIPTION,
		};

		dispatch(createReport(payload));
	};

	const renderReportInvalidBookButton = () => {
		return (
			<>
				{reportBookResult?.id && (
					<div className="report-book-alert">
						<div className="alert alert-success " role="alert">
							Thank you for reporting this book! We will look into this issue
							shortly.
						</div>
					</div>
				)}
				<Button
					color="secondary"
					onClick={handleSubmit(reportInvalidBook)}
					disabled={!!reportBookResult?.id || submitting}
					className="report-book-button"
				>
					Report this page!
				</Button>
			</>
		);
	};

	return (
		<ErrorWrapper>
			{addPageHead("Book not found!")}
			<h1>This book could not be loaded.</h1>
			<h5 className="error-code-message">
				We apologize for the inconvenience.{" "}
				<span role="img" aria-label="sad">
					&#128533;
				</span>{" "}
				Please help us by reporting this page as broken.
			</h5>
			{renderReportInvalidBookButton()}
		</ErrorWrapper>
	);
};

BookDetailsError.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
	form: "reportInvalidBookForm",
})(BookDetailsError);
