import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const style = {
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	backgroundPosition: "center",
};

const CoverImage = props => {
	let styles = {backgroundImage: `url(${props.src})`, ...style};
	if (props.style) {
		styles = Object.assign(styles, props.style);
	}
	return <div style={styles} {..._.omit(props, ["src", "style"])} />;
};

CoverImage.propTypes = {
	src: PropTypes.string.isRequired,
	style: PropTypes.shape({}),
};

export default CoverImage;
