import React from "react";
import url from "url";

const Books = props => (
	<img
		alt="Stack of books"
		src={url.resolve(process.env.PUBLIC_URL, "/img/books.svg")}
		{...props}
	/>
);

export default Books;
