import {
	AUTOFILL_BOOKS,
	GET_BOOK,
	POPULAR_BOOKS,
	RECOMMENDED_BOOKS,
	SEARCH_BOOKS,
	SIMILAR_BOOKS,
	ALL_POPULAR_BOOKS,
	ALL_RECOMMENDED_BOOKS,
	IS_SUCCESS,
	IS_ERROR,
	CREATE_REPORT,
} from "../actions/types";

const _ = require("lodash");

const INITIAL_STATE = {
	autofillResults: null,
	details: null,
	popular: null,
	recommended: null,
	searchResults: null,
	similar: null,
	bookDetailsError: null,
	reportBookResult: null,
};

export default (state = INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
	case AUTOFILL_BOOKS:
		return {...state, autofillResults: payload};
	case GET_BOOK:
		if (payload.status === IS_SUCCESS) {
			const data = payload.data;
			return {
				...state,
				bookDetailsError: null,
				details: _.omit(data, ["similarBooks"]),
				similar: data.similarBooks,
			};
		}
		if (payload.status === IS_ERROR) {
			return {
				...state,
				bookDetailsError: payload.error,
				details: null,
				similar: null,
			};
		}
		return state;
	case POPULAR_BOOKS:
		return {...state, popular: payload};
	case ALL_POPULAR_BOOKS:
		return {...state, allPopularBooks: payload};
	case RECOMMENDED_BOOKS:
		return {...state, recommended: payload};
	case ALL_RECOMMENDED_BOOKS:
		return {...state, allRecommendedBooks: payload};
	case SEARCH_BOOKS:
		return {...state, searchResults: payload};
	case SIMILAR_BOOKS:
		return {...state, similar: payload};
	case CREATE_REPORT:
		return {...state, reportBookResult: payload};
	default:
		return state;
	}
};
