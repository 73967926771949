import React, {Component} from "react";
import {Container, Row, Col} from "reactstrap";

import "../scss/FormPage.scss";
import "../scss/Contact.scss";
import {addPageHead} from "../config";
import ContactForm from "../components/ContactForm";
import ManOnLaptop from "../components/img/ManOnLaptop";

class Contact extends Component {
	render() {
		return (
			<div className="main formpage contact align-items-stretch">
				{addPageHead(
					"Contact",
					"Let us know what you think of our website! If you have any questions, comments, or anything else to tell us, feel free to send us an email.",
				)}

				<span id="bottom-slant" />

				<div className="formpage-content-wrapper">
					<div className="formpage-content">
						<Row
							id="man-on-laptop-m"
							className="d-flex justify-content-center align-items-center d-lg-none"
						>
							<ManOnLaptop />
						</Row>

						<Row
							id="man-on-laptop"
							className="justify-content-center align-items-center d-none d-lg-flex"
						>
							<ManOnLaptop />
						</Row>

						<Row className="m-0">
							<Col lg={{size: 5, order: 2}} xl={{size: 6}}>
								<section className="formpage-description">
									<Row>
										<Container>
											<h1 className="formpage-desc-title">Contact Us</h1>
											<p className="formpage-desc-text">
												Let us know what you think of our website! If you have
												any questions, comments, or anything else to tell us,
												feel free to send us an email.
											</p>
										</Container>
									</Row>
								</section>
							</Col>

							<Col
								className="wrapper formpage-form-wrapper"
								lg={{size: 7, order: 1}}
								xl={{size: 6}}
							>
								<section className="formpage-form">
									<Col>
										<h2 className="formpage-form-title">Send us a message</h2>
										<ContactForm />
									</Col>
								</section>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default Contact;
