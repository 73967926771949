import React from "react";
import {Button as ReactstrapButton} from "reactstrap";

export default class Button extends React.Component {
	render() {
		// colours:
		// "primary",
		// "secondary",
		// "success",
		// "danger",
		// "warning",
		// "info",
		// "light",
		// "dark",
		return <ReactstrapButton {...this.props} />;
	}
}
