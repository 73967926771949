export const ONE_HOUR_IN_SECONDS = 3600;
export const TWO_HOURS_IN_SECONDS = 7200;
export const FOUR_HOURS_IN_SECONDS = 14400;
export const SIX_HOURS_IN_SECONDS = 21600;
export const EIGHT_HOURS_IN_SECONDS = 28800;

// cookie constants
export const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 365;
export const FOUR_YEARS_IN_SECONDS = ONE_YEAR_IN_SECONDS * 4;
export const PERSONALIZED_READING_SPEED_COOKIE = "personalizedReadingSpeed";
export const HLTR_TOKEN_COOKIE = "hltr_token";
export const TEN_HOURS_IN_SECONDS = 36000;
