import React from "react";
import url from "url";

const TopBookshelf = props => (
	<img
		alt="Top bookshelf"
		src={url.resolve(process.env.PUBLIC_URL, "/img/top-bookshelf.svg")}
		{...props}
	/>
);

export default TopBookshelf;
