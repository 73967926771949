import React from "react";
import {useLocation} from "react-router-dom";

const ScrollToTop = ({children}) => {
	const location = useLocation();

	React.useEffect(() => {
		document.getElementById("root").scroll(0, 0);
	}, [location]);

	return children;
};

export default ScrollToTop;
