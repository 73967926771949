import React, {Component} from "react";
import {Row, Col} from "reactstrap";
import {Link} from "react-router-dom";

import "../scss/Signup.scss";
import {addPageHead} from "../config";
import FormPage from "../components/FormPage";
import GoodreadsButton from "../components/GoodreadsButton";
import LoginSignupDesc from "../components/LoginSignupDesc";
import SignupForm from "../components/SignupForm";
import ManReading from "../components/img/ManReading";

class Signup extends Component {
	render() {
		return (
			<FormPage image={<ManReading />} description={<LoginSignupDesc />}>
				{addPageHead("Sign Up")}
				<Col>
					<h1>Sign Up</h1>
					<SignupForm />
					<Row className="separator">or</Row>
					<GoodreadsButton>Sign up with GoodReads</GoodreadsButton>
					<p className="signup-alternative-text">
						Already a member?{" "}
						<Link className="secondary" to="/login">
							Log In
						</Link>
					</p>
				</Col>
			</FormPage>
		);
	}
}

export default Signup;
