import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Form, FormFeedback} from "reactstrap";
import PropTypes from "prop-types";

import {formType} from "../types";
import {createPasswordReset} from "../actions";
import Button from "../components/lib/Button";
import history from "../history";
import Input from "../components/lib/Input";

class ResetPasswordRequestForm extends Component {
	componentDidUpdate() {
		if (this.props.loggedIn) {
			history.push("/account/books");
		}
	}
	onSubmit = formValues => {
		return this.props.createPasswordReset(formValues);
	};
	responseMessage(submitSucceeded, error) {
		if (submitSucceeded) {
			return (
				<FormFeedback valid={true} style={{display: "block"}}>
					We&apos;ve sent a password reset link to this email if it exists in
					our database.
				</FormFeedback>
			);
		}
		return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
	}
	render() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
		} = this.props;
		return (
			<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
				<Field
					id="email"
					name="email"
					component={Input}
					placeholder="Email"
					label="Email"
					autoComplete="username"
				/>
				<Button
					id="submit"
					name="submit"
					disabled={submitting || submitSucceeded}
					color="primary"
				>
					Submit
				</Button>
				{!(submitSucceeded || submitFailed)
					? null
					: this.responseMessage(submitSucceeded, error)}
			</Form>
		);
	}
}

ResetPasswordRequestForm.propTypes = {
	...formType,
	loggedIn: PropTypes.bool,
	createPasswordReset: PropTypes.func.isRequired,
};

const formWrapped = reduxForm({
	form: "resetPasswordRequestForm",
})(ResetPasswordRequestForm);

function mapStateToProps({account: {loggedIn}}) {
	return {loggedIn};
}

export default connect(mapStateToProps, {createPasswordReset})(formWrapped);
