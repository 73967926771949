import React from "react";
import {Container, Row} from "reactstrap";

const LoginSignupDesc = () => (
	<section className="formpage-description">
		<Row>
			<Container>
				<h2 className="formpage-desc-title">Track your times!</h2>
				<p className="formpage-desc-text">
					Connect your GoodReads account to see how much time you&apos;ve spent
					reading the books you&apos;ve read, and how much time it&apos;ll take
					to read the books you want to read.
				</p>
			</Container>
		</Row>
	</section>
);

export default LoginSignupDesc;
