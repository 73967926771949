import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../components/lib/Spinner";
import {emailLogIn} from "../actions";

class EmailLogin extends Component {
	componentDidMount() {
		const {token} = this.props.match.params;
		this.props.emailLogIn(token);
	}

	render() {
		return <Spinner />;
	}
}

EmailLogin.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			token: PropTypes.string.isRequired,
		}),
	}),
	emailLogIn: PropTypes.func.isRequired,
};

export default connect(null, {emailLogIn})(EmailLogin);
