import React, {Component} from "react";
import {Col} from "reactstrap";
import PropTypes from "prop-types";

import "../scss/ResetPassword.scss";
import {addPageHead} from "../config";
import FormPage from "../components/FormPage";
import SetNewPasswordForm from "../components/SetNewPasswordForm";
import ManLounging from "../components/img/ManLounging";

class SetNewPassword extends Component {
	render() {
		const {token} = this.props.match.params;
		return (
			<FormPage image={<ManLounging />}>
				{addPageHead("Log In")}
				<Col>
					<h1>Set a New Password</h1>
					<SetNewPasswordForm initialValues={{code: token}} />
				</Col>
			</FormPage>
		);
	}
}

SetNewPassword.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			token: PropTypes.string.isRequired,
		}),
	}),
};

export default SetNewPassword;
