import React from "react";
import {
	FormFeedback,
	FormGroup,
	FormText,
	Input as ReactstrapInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import Button from "./lib/Button";
import Spinner from "./lib/Spinner";

const Input = props => {
	const {helpText, input, isSmall, label, meta, placeholder} = props;
	const {error: errorText = null, warning: validText = null, submitting} = meta;

	//return form group
	return (
		<FormGroup style={{margin: 0}}>
			{label === null ? null : <Label for={input.name}>{label}</Label>}
			<InputGroup
				className={"search-input-group " + (isSmall ? "search-small" : "")}
				style={{margin: 0}}
			>
				<InputGroupAddon addonType="prepend" className="search-icon-wrapper">
					<InputGroupText>
						<FontAwesomeIcon icon={["far", "search"]} />
					</InputGroupText>
				</InputGroupAddon>
				<ReactstrapInput {...input} placeholder={placeholder} />
				<InputGroupAddon addonType="append">
					<Button color="primary" type="submit" disabled={submitting}>
						{submitting ? (
							<Spinner size="sm" type="border" color="secondary" />
						) : (
							"Search"
						)}
					</Button>
				</InputGroupAddon>
			</InputGroup>
			{errorText === null && validText === null ? null : (
				<FormFeedback>{errorText || validText}</FormFeedback>
			)}
			{helpText === null ? null : <FormText>{helpText}</FormText>}
		</FormGroup>
	);
};

Input.defaultProps = {
	helpText: null,
	input: {},
	label: null,
	placeholder: null,
};

Input.propTypes = {
	helpText: PropTypes.string,
	input: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
	isSmall: PropTypes.bool.isRequired,
	label: PropTypes.string,
	meta: PropTypes.shape({
		error: PropTypes.string,
		invalid: PropTypes.bool.isRequired,
		touched: PropTypes.bool.isRequired,
		valid: PropTypes.bool.isRequired,
		submitting: PropTypes.bool.isRequired,
		warning: PropTypes.string,
	}).isRequired,
	placeholder: PropTypes.string,
};

export default Input;
