import React, {Component} from "react";
import {connect} from "react-redux";
import {Table, Container} from "reactstrap";
import PropTypes from "prop-types";

import Spinner from "../../components/lib/Spinner";
import AddBookToListModal from "./AddBookToListModal";
import {
	getAllPopularBooks,
	addToPopularBooks,
	deletePopularBookFromList,
	getAllRecommendedBooks,
	addToRecommendedBooks,
	deleteRecommendedBookFromList,
} from "../../actions";
import {bookType} from "../../types";

class ManageBooks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentType: "",
		};
	}

	componentDidMount() {
		const {type} = this.props.match.params;

		if (type === "popular") {
			this.props.getAllPopularBooks();
			this.setState({currentType: "popular"});
		} else if (type === "recommended") {
			this.props.getAllRecommendedBooks();
			this.setState({currentType: "recommended"});
		}
	}

	renderBookList() {
		const books =			this.state.currentType === "popular"
			? this.props.popularBooks
			: this.props.recommendedBooks;

		if (!books) {
			return <Spinner />;
		}

		return (
			<div style={{marginTop: 16, marginBottom: 16}}>
				<div style={{float: "right", margin: 8}}>
					<AddBookToListModal
						currentType={this.state.currentType}
						addToBooksInList={
							this.state.currentType === "popular"
								? this.props.addToPopularBooks
								: this.props.addToRecommendedBooks
						}
						fetchBookOfList={
							this.state.currentType === "popular"
								? this.props.getAllPopularBooks
								: this.props.getAllRecommendedBooks
						}
					/>
				</div>
				<Table responsive bordered className="table">
					<thead>
						<tr>
							<th></th>
							<th>Book Id</th>
							<th>Book Title</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{books.map((data, index) => {
							return (
								<tr key={index}>
									<th scope="row">{index + 1}</th>
									<td>{data.id}</td>
									<td>{data.title}</td>
									<td>
										<u
											className="alert-link link underline"
											onClick={() => {
												this.state.currentType === "popular"
													? this.props.deletePopularBookFromList(data.id)
													: this.props.deleteRecommendedBookFromList(data.id);
												setTimeout(() => {
													this.state.currentType === "popular"
														? this.props.getAllPopularBooks()
														: this.props.getAllRecommendedBooks();
												}, 2000);
											}}
										>
											Delete
										</u>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		);
	}

	render() {
		return (
			<Container className="wrapper" style={{marginTop: 35, marginBottom: 35}}>
				{this.renderBookList()}
			</Container>
		);
	}
}

ManageBooks.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			type: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	popularBooks: PropTypes.arrayOf(PropTypes.shape(bookType)),
	getAllPopularBooks: PropTypes.func.isRequired,
	addToPopularBooks: PropTypes.func.isRequired,
	deletePopularBookFromList: PropTypes.func.isRequired,
	recommendedBooks: PropTypes.arrayOf(PropTypes.shape(bookType)),
	getAllRecommendedBooks: PropTypes.func.isRequired,
	addToRecommendedBooks: PropTypes.func.isRequired,
	deleteRecommendedBookFromList: PropTypes.func.isRequired,
};

function mapStateToProps({book}) {
	return {
		recommendedBooks: book.allRecommendedBooks,
		popularBooks: book.allPopularBooks,
	};
}

export default connect(mapStateToProps, {
	getAllPopularBooks: getAllPopularBooks,
	addToPopularBooks: addToPopularBooks,
	deletePopularBookFromList: deletePopularBookFromList,
	getAllRecommendedBooks: getAllRecommendedBooks,
	addToRecommendedBooks: addToRecommendedBooks,
	deleteRecommendedBookFromList: deleteRecommendedBookFromList,
})(ManageBooks);
