import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Form, FormFeedback} from "reactstrap";
import PropTypes from "prop-types";

import {formType} from "../types";
import {getLinkGoodreadsCode} from "../actions";
import GoodreadsButton from "../components/GoodreadsButton";

class ThirdPartyAuthForm extends Component {
	onSubmit = () => {
		// get auth token
		this.props.getLinkGoodreadsCode();
	};
	responseMessage(submitSucceeded, error) {
		if (submitSucceeded) {
			return (
				<FormFeedback valid={true} style={{display: "block"}}>
					Your GoodReads account settings were updated successfully.
				</FormFeedback>
			);
		}
		return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
	}
	render() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
		} = this.props;
		return (
			<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
				<GoodreadsButton
					id="submit"
					name="submit"
					disabled={submitting || submitSucceeded}
					href={null}
				>
					Connect your GoodReads account
				</GoodreadsButton>
				{submitFailed ? this.responseMessage(submitSucceeded, error) : null}
			</Form>
		);
	}
}

ThirdPartyAuthForm.propTypes = {
	...formType,
	getLinkGoodreadsCode: PropTypes.func.isRequired,
};

const formWrapped = reduxForm({
	form: "thirdPartyAuthForm",
})(ThirdPartyAuthForm);

export default connect(null, {getLinkGoodreadsCode})(formWrapped);
