import React from "react";
import url from "url";

const BottomBookshelf = props => (
	<img
		alt="Bottom bookshelf"
		src={url.resolve(process.env.PUBLIC_URL, "/img/bottom-bookshelf.svg")}
		{...props}
	/>
);

export default BottomBookshelf;
