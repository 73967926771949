import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Form, FormFeedback, Col, Row} from "reactstrap";
import PropTypes from "prop-types";

import {createSuggestion} from "../actions";
import {humanizeSeconds, strToDuration} from "../config";
import {formType} from "../types";
import Button from "../components/lib/Button";
import Input from "../components/lib/Input";

class SuggestForm extends Component {
	// state = {genres: null};
	// constructor(props) {
	// 	super(props);
	// 	this.renderGenreSelection = this.renderGenreSelection.bind(this);
	// 	this.toggleGenreSelection = this.toggleGenreSelection.bind(this);
	// }
	// componentDidMount() {
	// 	this.props.getGenres();
	// }
	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	if (
	// 		nextProps.genres !== null
	// 		&& (prevState.genres === null
	// 			|| nextProps.genres.length !== prevState.genres.length)
	// 	) {
	// 		return {
	// 			...prevState,
	// 			genres: nextProps.genres.map(g => ({...g, selected: false})),
	// 		};
	// 	}
	// 	return null;
	// }
	// renderGenreSelection(genres) {
	// 	if (genres === null) {
	// 		return null;
	// 	}
	// 	return (
	// 		<Row>
	// 			{genres.map(g => (
	// 				<Col sm={3} key={g.id}>
	// 					<BookImage
	// 						id={g.id}
	// 						image={g.image}
	// 						title={g.name}
	// 						onClick={this.toggleGenreSelection}
	// 						selected={g.selected}
	// 					/>
	// 				</Col>
	// 			))}
	// 		</Row>
	// 	);
	// }
	// toggleGenreSelection(id) {
	// 	this.setState(prevState => {
	// 		prevState.genres.forEach(g => {
	// 			if (g.id === id) {
	// 				g.selected = !g.selected;
	// 			}
	// 		});
	// 		const {genres} = prevState;
	// 		const genresInput =				genres === null
	// 			? ""
	// 			: genres
	// 				.filter(g => g.selected)
	// 				.map(g => g.id)
	// 				.join(",");
	// 		this.props.change("genres", genresInput);
	// 		return prevState;
	// 	});
	// }
	onSubmit = formValues => {
		formValues.readingTimeRange = [
			strToDuration(formValues.minTimeString || ""),
			strToDuration(formValues.maxTimeString || ""),
		];
		return this.props.createSuggestion(formValues);
	};
	responseMessage(submitSucceeded, error) {
		if (submitSucceeded) {
			return null;
		}
		return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
	}
	render() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
		} = this.props;
		// const {genres} = this.state;
		return (
			<div className="search-container">
				<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
					{/* <Field
                        id="genres"
                        name="genres"
                        component={Input}
                        label="Select genres that you're interested in"
                        type="hidden"
                    /> */}
					{/* {this.renderGenreSelection(genres)} */}
					<Row>
						<Col sm={6}>
							<Field
								id="minTimeString"
								name="minTimeString"
								component={Input}
								placeholder="2 hours and 15 minutes"
								label="Minimum reading time"
							/>
						</Col>
						<Col sm={6}>
							<Field
								id="maxTimeString"
								name="maxTimeString"
								component={Input}
								placeholder="8 hours and 45 minutes"
								label="Maximum reading time"
							/>
						</Col>
					</Row>
					<Button id="submit" name="submit" disabled={submitting}>
						Find books
					</Button>
					{!(submitSucceeded || submitFailed)
						? null
						: this.responseMessage(submitSucceeded, error)}
				</Form>
			</div>
		);
	}
}

SuggestForm.propTypes = {
	...formType,
	// genres: PropTypes.shape(shelfType),
	createSuggestion: PropTypes.func.isRequired,
};

const makeError = timeString => {
	if (timeString === undefined) {
		return;
	}

	const timeInSeconds = strToDuration(timeString);
	if (timeInSeconds >= 1800) {
		return;
	}
	if (timeInSeconds < 1) {
		return "We don't understand this input :(";
	}
	return "Please enter a time of at least half an hour";
};

const validate = formValues => {
	const {minTimeString, maxTimeString} = formValues;
	let errors = {
		minTimeString: makeError(minTimeString),
		maxTimeString: makeError(maxTimeString),
	};
	if (
		errors.minTimeString === undefined
		&& errors.maxTimeString === undefined
	) {
		const min = strToDuration(minTimeString || "");
		const max = strToDuration(maxTimeString || "");
		if (max > 0 && min > max) {
			errors.maxTimeString = "Maximum time should be greater than minimum time";
		}
	}
	return errors;
};

const makeWarning = timeString => {
	if (timeString === undefined) {
		return;
	}

	const timeInSeconds = strToDuration(timeString);
	if (timeInSeconds < 1800) {
		return;
	}
	return `Your input was interpreted as: '${humanizeSeconds(timeInSeconds)}'`;
};

const warn = formValues => {
	const {minTimeString, maxTimeString} = formValues;
	let warnings = {};
	warnings.minTimeString = makeWarning(minTimeString);
	warnings.maxTimeString = makeWarning(maxTimeString);
	return warnings;
};

const formWrapped = reduxForm({
	form: "SuggestForm",
	validate,
	warn,
})(SuggestForm);

function mapStateToProps({suggestion: {genres = null, suggestion = null}}) {
	return {genres, suggestion};
}

export default connect(mapStateToProps, {createSuggestion})(formWrapped);
