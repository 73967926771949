import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import {addPageHead} from "../config";
import {logOut} from "../actions";

class Logout extends React.Component {
	componentDidMount() {
		this.props.logOut();
	}
	render() {
		if (this.props.loggedIn) {
			return <Fragment>{addPageHead("Log Out")}</Fragment>;
		}
		return <Redirect to="/" />;
	}
}

Logout.propTypes = {
	logOut: PropTypes.func.isRequired,
	loggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps({account: {loggedIn}}) {
	return {loggedIn};
}

export default connect(mapStateToProps, {logOut})(Logout);
