import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	Form,
	FormFeedback,
} from "reactstrap";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import {autofillBooks, searchBooks} from "../actions";
import {bookType, formType} from "../types";
import {bookUrl} from "../config";
import history from "../history";
import SearchInput from "../components/SearchInput";
import withScreenWidth from "../hoc/withScreenWidth";

class SearchForm extends Component {
	immediateID;
	state = {
		focused: false,
		cursor: -1,
	};

	constructor(props) {
		super(props);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	// manage focus for autofill
	// https://medium.com/@jessebeach/dealing-with-focus-and-blur-in-a-composite-widget-in-react-90d3c3b49a9b
	onBlur() {
		this.immediateID = setImmediate(() => {
			if (this.state.focused) {
				this.setState({focused: false, cursor: -1});
			}
		});
	}

	onFocus() {
		clearImmediate(this.immediateID);
		if (!this.state.focused) {
			this.setState({focused: true});
		}
	}

	handleKeyDown(e) {
		const {cursor} = this.state;
		const {autofillResults} = this.props;
		// arrow up/down button should select next/previous list element
		if (autofillResults !== null) {
			if (e.keyCode === 38 && cursor > -1) {
				this.setState(prevState => ({
					cursor: prevState.cursor - 1,
				}));
				e.preventDefault();
			} else if (e.keyCode === 40 && cursor < autofillResults.length - 1) {
				this.setState(prevState => ({
					cursor: prevState.cursor + 1,
				}));
			} else if (e.keyCode === 13) {
				if (cursor > -1) {
					// use link
					const bookId = autofillResults[cursor].id;
					history.push(bookUrl(bookId));
				}
				//else propagate
			}
		}
	}

	onSearchChange = (_, value) => {
		// this.props.autofillBooks(value);
		this.setState({cursor: -1});
	};
	onSubmit = async ({search}) => {
		return this.props.searchBooks(search);
	};
	responseMessage(submitFailed, error) {
		if (!submitFailed) {
			return null;
		}
		return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
	}
	renderAutofill(autofillResults) {
		if (autofillResults === null || !this.state.focused) {
			return null;
		}
		const {cursor} = this.state;
		let items = autofillResults.map((r, i) => (
			<DropdownItem
				tag={Link}
				to={bookUrl(r.id)}
				key={r.id}
				active={i === cursor}
			>
				{r.title}
			</DropdownItem>
		));
		if (autofillResults.length === 0) {
			items = <DropdownItem disabled>No results :(</DropdownItem>;
		}

		return (
			<Dropdown isOpen={true} className="search-autofill" toggle={() => {}}>
				<DropdownMenu style={{opacity: 1, width: "100%"}}>{items}</DropdownMenu>
			</Dropdown>
		);
	}

	renderSearchPlaceholder = () => {
		const {screenWidth} = this.props;
		if (screenWidth < 576) {
			return "Search for a book";
		}
		return "Search a book for its reading time";
	};

	render() {
		const {autofillResults, isSmall, error, submitFailed} = this.props;
		return (
			<div
				className="search-container"
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onKeyDown={this.handleKeyDown}
			>
				<Form
					onSubmit={this.props.handleSubmit(this.onSubmit)}
					autoComplete="off"
				>
					<Field
						id="search"
						name="search"
						isSmall={isSmall}
						component={SearchInput}
						onChange={this.onSearchChange}
						placeholder={this.renderSearchPlaceholder()}
					/>
					{this.responseMessage(submitFailed, error)}
				</Form>
				{this.renderAutofill(autofillResults)}
			</div>
		);
	}
}

SearchForm.defaultProps = {
	isSmall: false,
};

SearchForm.propTypes = {
	...formType,
	autofillResults: PropTypes.arrayOf(PropTypes.shape(bookType)),
	isSmall: PropTypes.bool.isRequired,
};

const validate = formValues => {
	const {search} = formValues;
	let errors = {};
	if (!search) {
		errors.search = "Please enter something";
	}
	return errors;
};

const formWrapped = reduxForm({
	form: "searchForm",
	validate,
})(SearchForm);

const mapStateToProps = ({book: {autofillResults}}) => {
	return {autofillResults};
};

export default withScreenWidth(connect(mapStateToProps, {autofillBooks, searchBooks})(formWrapped));
