import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
	Container,
	Row,
	Pagination,
	PaginationItem,
	PaginationLink,
} from "reactstrap";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import {addPageHead, humanizeSeconds} from "../../config";
import {bookType, shelfType} from "../../types";
import {MY_BOOKS} from "./types";
import {recommendedBooks, getReadingSpeed, getShelves} from "../../actions";
import AccountWrapper from "../../components/AccountWrapper";
import BookCarousel from "../../components/BookCarousel";
import BookCollection from "../../components/BookCollection";
import NewShelfButton from "../../components/NewShelfButton";
import SearchForm from "../../components/SearchForm";
import Spinner from "../../components/lib/Spinner";

class Bookshelf extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentPage: 0,
		};
	}

	componentDidMount() {
		if (this.props.recommended === null) {
			this.props.getRecommendedBooks();
		}
		if (this.props.readingSpeed === null) {
			this.props.getReadingSpeed();
		}
		this.props.getShelves({pageNumber: 1});
	}

	renderPagination(shelf, pagesCount) {
		const {books} = shelf;

		return (
			<>
				{books && (
					<Row className="justify-content-center mt-4">
						<Pagination aria-label="Shelves Pagination">
							<PaginationItem disabled={this.state.currentPage <= 0}>
								<PaginationLink
									onClick={e =>
										this.handlePageClick(e, this.state.currentPage - 1)
									}
									previous
									href="#"
								/>
							</PaginationItem>
							{[...Array(pagesCount)].map((page, i) => (
								<PaginationItem active={i === this.state.currentPage} key={i}>
									<PaginationLink
										onClick={e => this.handlePageClick(e, i)}
										href="#"
									>
										{i + 1}
									</PaginationLink>
								</PaginationItem>
							))}
							<PaginationItem
								disabled={this.state.currentPage >= pagesCount - 1}
							>
								<PaginationLink
									onClick={e =>
										this.handlePageClick(e, this.state.currentPage + 1)
									}
									next
									href="#"
								/>
							</PaginationItem>
						</Pagination>
					</Row>
				)}
			</>
		);
	}

	renderContent(shelf, pagesCount) {
		if (!shelf) {
			return <Spinner />;
		}

		const {
			books,
			formattedName,
			totalAverageReadingTime,
			totalPersonalizedReadingTime,
		} = shelf;
		const readingSpeed = this.props.readingSpeed || "N/A";

		if (books.length === 0) {
			const {recommended} = this.props;
			return (
				<Fragment>
					{addPageHead(formattedName)}
					<p className="h2">Add some books to this shelf!</p>
					<p className="subtitle">
						Search for books and add them from their individual page!
					</p>
					<div className="shelves-row">
						<SearchForm />
					</div>
					<p className="subtitle">Or, check out some books we recommend!</p>
					<Row className="shelves-row">
						<BookCarousel books={recommended} />
					</Row>
				</Fragment>
			);
		}

		return (
			<Fragment>
				{addPageHead(formattedName)}
				<div className="reading-speed-display">
					<p className="h2">
						{totalPersonalizedReadingTime
							? `Based on your saved reading speed of ${readingSpeed} WPM, it would take you`
							: "The average reader would take"}
					</p>
					<p id="user-wpm">
						<b>
							{humanizeSeconds(totalPersonalizedReadingTime || totalAverageReadingTime)}
						</b>
					</p>
					<p className="h2">
						to read the following books in{" "}
						<span style={{fontStyle: "italic"}}>{formattedName}</span>.
					</p>
				</div>
				{this.renderPagination(shelf, pagesCount)}
				<Row className="m-0">
					{books.length > 0 ? (
						<BookCollection items={books} alignItems="normal" />
					) : null}
				</Row>
				{this.renderPagination(shelf, pagesCount)}
			</Fragment>
		);
	}

	handlePageClick(e, index) {
		e.preventDefault();
		this.setState({
			currentPage: index,
		});
		return this.props.getShelves({pageNumber: index + 1});
	}

	render() {
		const {shelves} = this.props;
		const shelfName = this.props.match.params.shelfName;
		const shelf = shelves !== null && shelves.find(s => s.name === shelfName);
		const {formattedName, bookCount} = shelf;
		const pagesCount = shelves !== null && Math.ceil(bookCount / 20);

		if (shelves !== null && !shelf) {
			return <Redirect to="/error" />;
		}

		return (
			<AccountWrapper page={MY_BOOKS} activeShelf={shelfName}>
				<Row className="align-items-center m-0 desktop">
					<h1 className="content-title">{formattedName}</h1>
					<NewShelfButton className="ml-auto" />
				</Row>
				<Container className="wrapper">
					{this.renderContent(shelf, pagesCount)}
				</Container>
			</AccountWrapper>
		);
	}
}

Bookshelf.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			shelfName: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	recommended: PropTypes.arrayOf(PropTypes.shape(bookType)),
	shelves: PropTypes.arrayOf(PropTypes.shape(shelfType)),
	readingSpeed: PropTypes.number,
	getRecommendedBooks: PropTypes.func.isRequired,
	getReadingSpeed: PropTypes.func.isRequired,
	getShelves: PropTypes.func.isRequired,
};

function mapStateToProps({
	account: {shelves, readingSpeed},
	book: {recommended},
}) {
	return {shelves, readingSpeed, recommended};
}

export default connect(mapStateToProps, {
	getRecommendedBooks: recommendedBooks,
	getReadingSpeed,
	getShelves,
})(Bookshelf);
