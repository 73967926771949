import React, {Component, Fragment} from "react";
import {Field, reduxForm, reset} from "redux-form";
import {
	Button,
	Col,
	Form,
	FormFeedback,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from "reactstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {formType} from "../../types";
import Input from "../../components/lib/Input";

class AddBookToListModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpenAddBookModal: true,
		};
		this.toggleAddBookModal = this.toggleAddBookModal.bind(this);
	}

	toggleAddBookModal() {
		this.setState({isOpenAddBookModal: !this.state.isOpenAddBookModal});
	}

	responseMessage(submitSucceeded, error) {
		if (!submitSucceeded) {
			return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
		}
	}

	handleAddBookModal(addToBooksInList, fetchBookOfList, currentType) {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
		} = this.props;

		const onSubmit = formValues => {
			this.setState({
				isOpenAddBookModal: !this.state.isOpenAddBookModal,
			});
			return addToBooksInList(formValues);
		};

		if (submitSucceeded) {
			fetchBookOfList();
		}

		return (
			<Modal
				isOpen={!this.state.isOpenAddBookModal}
				toggle={this.toggleAddBookModal}
				className={this.props.modalClassName}
				centered={true}
			>
				<ModalHeader toggle={this.toggleAddBookModal}>
					Add {currentType} book
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<Field
							id="bookUrl"
							name="bookUrl"
							type="text"
							component={Input}
							placeholder="Book Link"
							label="Book Link"
						/>
						{!(submitSucceeded || submitFailed)
							? null
							: this.responseMessage(submitSucceeded, error)}
						<Row className="login-btns row justify-content-center m-2">
							<Col md={6}>
								<Button
									id="submit"
									name="submit"
									disabled={submitting || submitSucceeded}
									color="primary"
								>
									Submit
								</Button>
							</Col>
						</Row>
					</Form>
				</ModalBody>
			</Modal>
		);
	}

	render() {
		const {addToBooksInList, fetchBookOfList, currentType} = this.props;

		const renderTitle = (
			<p onClick={this.toggleAddBookModal.bind(this)}>
				<u className="alert-link link underline">Add {currentType} book</u>
			</p>
		);

		return (
			<Fragment>
				<Row className="test-row justify-content-end">
					<Col md={12} lg={12}>
						{renderTitle}
					</Col>
				</Row>
				{this.handleAddBookModal(
					addToBooksInList,
					fetchBookOfList,
					currentType,
				)}
			</Fragment>
		);
	}
}

AddBookToListModal.propTypes = {
	...formType,
	modalClassName: PropTypes.string,
	addToBooksInList: PropTypes.func.isRequired,
	fetchBookOfList: PropTypes.func.isRequired,
	currentType: PropTypes.string.isRequired,
};

const validate = formValues => {
	const {bookUrl = ""} = formValues;
	let errors = {};
	if (bookUrl === "") {
		errors.bookUrl = "Please enter Book url";
	}
	return errors;
};

const afterSubmitSuccess = (result, dispatch) =>
	dispatch(reset("addBookLinkForm"));

const formWrapped = reduxForm({
	form: "addBookLinkForm",
	validate,
	onSubmitSuccess: afterSubmitSuccess,
})(AddBookToListModal);

export default connect(null)(formWrapped);
