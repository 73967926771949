import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Form, FormFeedback} from "reactstrap";
import PropTypes from "prop-types";

import {formType} from "../types";
import {updatePassword} from "../actions";
import Button from "../components/lib/Button";
import Input from "../components/lib/Input";

class AccountPasswordForm extends Component {
	onSubmit = formValues => {
		return this.props.updatePassword(formValues);
	};
	responseMessage(submitSucceeded, error) {
		if (submitSucceeded) {
			return (
				<FormFeedback valid={true} style={{display: "block"}}>
					Your password was updated successfully.
				</FormFeedback>
			);
		}
		return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
	}
	render() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
		} = this.props;
		return (
			<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="on">
				<Field
					id="oldPassword"
					name="oldPassword"
					component={Input}
					placeholder="Old password"
					type="password"
					label="Old password"
					autoComplete="current-password"
				/>
				<Field
					id="newPassword"
					name="newPassword"
					component={Input}
					placeholder="New password"
					type="password"
					label="New password"
					autoComplete="new-password"
				/>
				<Field
					id="confirmNewPassword"
					name="confirmNewPassword"
					component={Input}
					placeholder="Confirm new password"
					type="password"
					label="Confirm new password"
					autoComplete="new-password"
				/>
				<Button
					id="submitPasswordForm"
					name="submit"
					disabled={submitting}
					color="primary"
				>
					Update Account
				</Button>
				{!(submitSucceeded || submitFailed)
					? null
					: this.responseMessage(submitSucceeded, error)}
			</Form>
		);
	}
}

AccountPasswordForm.propTypes = {
	...formType,
	updatePassword: PropTypes.func.isRequired,
	loggedIn: PropTypes.bool,
};

const validate = formValues => {
	const {newPassword = "", confirmNewPassword = ""} = formValues;
	let errors = {};
	if (newPassword.length < 8) {
		errors.newPassword = "Your password must be at least 8 characters long";
	}
	if (confirmNewPassword !== newPassword) {
		errors.confirmNewPassword = "Your passwords don't seem to match";
	}
	return errors;
};

const formWrapped = reduxForm({
	form: "accountPasswordForm",
	validate,
})(AccountPasswordForm);

function mapStateToProps({account: {loggedIn}}) {
	return {loggedIn};
}

export default connect(mapStateToProps, {updatePassword})(formWrapped);
