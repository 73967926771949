import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import {goodreadsUrl} from "../config";
import Button from "./lib/Button";

const GoodreadsButton = props => {
	return (
		<Button
			href={goodreadsUrl}
			className="goodreads-btn"
			{...props}
			color="dark"
		>
			<FontAwesomeIcon icon={["fab", "goodreads"]} />
			{props.children ? props.children : "GoodReads"}
		</Button>
	);
};

GoodreadsButton.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default GoodreadsButton;
