import React, {Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import {amazonLink} from "../config";
import Button from "./lib/Button";
import OutboundLink from "./OutboundLink";

const AmazonButton = props => {
	const {asin = null, title = ""} = props;
	if (!asin && !title) {
		return null;
	}
	return (
		<Fragment>
			<p>
				<small className="amazon-affiliate-desc text-muted">
					As an Amazon Associate, How Long to Read earns from qualifying
					purchases. Thank you for helping to keep this site running!
				</small>
			</p>
			<OutboundLink
				to={amazonLink(props.asin, title)}
				target="_blank"
				rel="noopener noreferrer"
				eventLabel="amazonButton"
			>
				<Button {...props} color="secondary">
					<FontAwesomeIcon icon={["fab", "amazon"]} />
					Check it out on Amazon!
				</Button>
			</OutboundLink>
		</Fragment>
	);
};

AmazonButton.propTypes = {
	asin: PropTypes.string,
	title: PropTypes.string,
};

export default AmazonButton;
