import React from "react";
import url from "url";

const WomanReading = props => (
	<img
		id="woman-reading-img"
		alt="Woman laying down and reading"
		src={url.resolve(process.env.PUBLIC_URL, "/img/woman-reading.svg")}
		{...props}
	/>
);

export default WomanReading;
