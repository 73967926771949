import {
	GET_READING_SPEED_TEST,
	GET_READING_SPEED_TESTS,
	CLEAR_READING_SPEED_TEST,
} from "../actions/types";

// const _ = require("lodash");

const INITIAL_STATE = {
	tests: null,
	currentTest: null,
};

export default (state = INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
	case GET_READING_SPEED_TEST:
		return {...state, currentTest: payload};
	case GET_READING_SPEED_TESTS:
		return {...state, tests: payload};
	case CLEAR_READING_SPEED_TEST:
		return {...state, currentTest: null};
	default:
		return state;
	}
};
