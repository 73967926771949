import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Form, FormFeedback} from "reactstrap";
import PropTypes from "prop-types";

import {formType} from "../types";
import {updatePasswordReset} from "../actions";
import Button from "../components/lib/Button";
import history from "../history";
import Input from "../components/lib/Input";

class SetNewPasswordForm extends Component {
	componentDidUpdate() {
		if (this.props.loggedIn) {
			history.push("/account/books");
		}
	}
	onSubmit = formValues => {
		return this.props.updatePasswordReset(formValues);
	};
	responseMessage(submitSucceeded, error) {
		if (submitSucceeded) {
			setTimeout(() => history.push("/login"), 3000);
			return (
				<FormFeedback valid={true} style={{display: "block"}}>
					Your password was set successfully. Redirecting you to login...
				</FormFeedback>
			);
		}
		return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
	}
	render() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
		} = this.props;
		return (
			<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
				<Field
					id="newPassword"
					name="newPassword"
					component={Input}
					placeholder="New password"
					type="password"
					label="New password"
					autoComplete="new-password"
				/>
				<Field
					id="confirmNewPassword"
					name="confirmNewPassword"
					component={Input}
					placeholder="Confirm new password"
					type="password"
					label="Confirm new password"
					autoComplete="new-password"
				/>
				<Button
					id="submit"
					name="submit"
					disabled={submitting || submitSucceeded}
					color="primary"
				>
					Submit
				</Button>
				{!(submitSucceeded || submitFailed)
					? null
					: this.responseMessage(submitSucceeded, error)}
			</Form>
		);
	}
}

SetNewPasswordForm.propTypes = {
	...formType,
	loggedIn: PropTypes.bool,
	updatePasswordReset: PropTypes.func.isRequired,
};

const validate = formValues => {
	const {newPassword = "", confirmNewPassword = ""} = formValues;
	let errors = {};
	if (newPassword.length < 8) {
		errors.newPassword = "Your password must be at least 8 characters long";
	}
	if (confirmNewPassword !== newPassword) {
		errors.confirmNewPassword = "Your passwords don't seem to match";
	}
	return errors;
};

const formWrapped = reduxForm({
	form: "setNewPasswordForm",
	fields: ["code"],
	validate,
})(SetNewPasswordForm);

function mapStateToProps({account: {loggedIn}}) {
	return {loggedIn};
}

export default connect(mapStateToProps, {updatePasswordReset})(formWrapped);
