import React from "react";
import {Spinner as ReactstrapSpinner} from "reactstrap";

const Spinner = props => {
	return (
		<div className="vh-center">
			<ReactstrapSpinner type="grow" color="primary" {...props} />
		</div>
	);
};

export default Spinner;
