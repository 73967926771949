import React from "react";
import url from "url";

const ManOnLaptop = props => (
	<img
		alt="Man sitting down using laptop"
		src={url.resolve(process.env.PUBLIC_URL, "/img/man-on-laptop.svg")}
		{...props}
	/>
);

export default ManOnLaptop;
