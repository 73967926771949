import React from "react";
import _ from "lodash";

import {formatNumber} from "../config";
import Collection from "./Collection";
import BookImage from "./BookImage";

class TestCollection extends Collection {
	renderItem = item => {
		const previousWpm = _.get(item, "previousResults[0].wpm");
		return (
			<div key={item.id} className="item-container d-flex flex-column">
				<BookImage {...item} link={`/account/reading-speed/${item.id}`} />
				{!previousWpm ? null : (
					<p className="collection-subtitle">
						Last result:{" "}
						<span className="secondary">{formatNumber(previousWpm)} WPM</span>
					</p>
				)}
			</div>
		);
	};
}

export default TestCollection;
