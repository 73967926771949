import React, {Component} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {Container, Form, FormFeedback} from "reactstrap";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";

import {addPageHead} from "../../config";
import {createShelf} from "../../actions";
import {formType, shelfType} from "../../types";
import {MY_BOOKS} from "./types";
import AccountWrapper from "../../components/AccountWrapper";
import Button from "../../components/lib/Button";
import ThirdPartyAuthForm from "../../components/ThirdPartyAuthForm";
import history from "../../history";
import Input from "../../components/lib/Input";

class CreateBookshelfForm extends Component {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.renderForm = this.renderForm.bind(this);
	}

	componentDidUpdate(prevProps) {
		const {createShelfResponse} = this.props;
		const {createShelfResponse: prevResponse} = prevProps;
		if (createShelfResponse === null) {
			return;
		}
		if (!_.isEqual(createShelfResponse, prevResponse)) {
			history.push(`/account/books/${createShelfResponse.name}`);
		}
	}
	onSubmit(formValues) {
		return this.props.createShelf(formValues);
	}
	responseMessage(submitSucceeded, error) {
		if (!submitSucceeded) {
			return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
		}
	}
	renderForm() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
			hasGoodreadsAccount,
		} = this.props;
		if (hasGoodreadsAccount === null) {
			return null;
		}
		if (hasGoodreadsAccount === false) {
			return (
				<div>
					<p>Connect your GoodReads account to access this feature!</p>
					<ThirdPartyAuthForm>Connect</ThirdPartyAuthForm>
				</div>
			);
		}
		return (
			<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
				<Field
					id="formattedName"
					name="formattedName"
					component={Input}
					placeholder="My new bookshelf"
					label="Bookshelf Name"
				/>
				<Button
					id="submit"
					name="Submit"
					disabled={submitting || submitSucceeded}
					color="primary"
				>
					Create
				</Button>
				{!(submitSucceeded || submitFailed)
					? null
					: this.responseMessage(submitSucceeded, error)}
			</Form>
		);
	}
	render() {
		return (
			<AccountWrapper page={MY_BOOKS}>
				{addPageHead("Create Bookshelf")}
				<h1 className="desktop">Create a New Bookshelf</h1>
				<Container className="wrapper">{this.renderForm()}</Container>
			</AccountWrapper>
		);
	}
}

CreateBookshelfForm.propTypes = {
	...formType,
	createShelfResponse: PropTypes.shape(shelfType),
	hasGoodreadsAccount: PropTypes.bool,
	createShelf: PropTypes.func.isRequired,
};

const validate = formValues => {
	const {formattedName = ""} = formValues;
	let errors = {};
	if (formattedName === "") {
		errors.formattedName = "Please enter a name for your bookshelf";
	}
	return errors;
};

const formWrapped = reduxForm({
	form: "CreateBookshelf",
	validate,
})(CreateBookshelfForm);

function mapStateToProps({account: {createShelfResponse, goodreadsId}}) {
	const hasGoodreadsAccount = goodreadsId === null ? null : goodreadsId > 0;
	return {createShelfResponse, hasGoodreadsAccount};
}

export default connect(mapStateToProps, {createShelf})(formWrapped);
