import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "../scss/BookImage.scss";
import {isExternalLink} from "../config";
import {itemType} from "../types";
import Books from "../components/img/Books";
import withScreenWidth from "../hoc/withScreenWidth";

const titleSizeConfig = {
	lg: [
		{max: 20, fontSize: 26},
		{max: 40, fontSize: 24},
		{max: 60, fontSize: 22},
		{max: 80, fontSize: 20},
		{max: Infinity, fontSize: 18},
	],
	md: [
		{max: 20, fontSize: 16},
		{max: 40, fontSize: 14},
		{max: 60, fontSize: 12},
		{max: 80, fontSize: 11},
		{max: Infinity, fontSize: 10},
	],
	// The max size is 22 for sm as the books are arranged in responsive columns here
	sm: [
		{max: 20, fontSize: 22},
		{max: 40, fontSize: 20},
		{max: 60, fontSize: 18},
		{max: 80, fontSize: 16},
		{max: Infinity, fontSize: 14},
	],
	xs: [
		{max: 20, fontSize: 18},
		{max: 40, fontSize: 16},
		{max: 60, fontSize: 14},
		{max: 80, fontSize: 12},
		{max: Infinity, fontSize: 10},
	],
	default: [{max: Infinity, fontSize: 18}],
};

const screenWidthBreakPoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1400,
};
class BookImage extends React.Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}
	renderInnerImage(link, openInNew, children) {
		if (link) {
			if (isExternalLink(link)) {
				return (
					<a
						href={link}
						target={openInNew ? "_blank" : undefined}
						className="v-center"
					>
						{children}
					</a>
				);
			}
			return (
				<Link to={link} target={openInNew ? "_blank" : undefined}>
					{children}
				</Link>
			);
		}
		return children;
	}

	renderSelected(selected) {
		if (selected === undefined || selected === null) {
			return null;
		}
		return (
			<div className={`book-img-cover vh-center ${selected ? "selected" : ""}`}>
				<div className="checkmark-circle vh-center">
					<FontAwesomeIcon className="checkmark" icon={["fal", "check"]} />
				</div>
			</div>
		);
	}

	handleClick() {
		const {id, onClick} = this.props;
		if (typeof onClick === "function") {
			onClick(id);
		}
	}

	getTitleStyle() {
		const {title, screenWidth} = this.props;
		const titleLength = title.length || 0;

		const {xl, md, sm} = screenWidthBreakPoints;

		const size =			screenWidth >= xl
			? "lg"
			: screenWidth >= md
				? "md"
				: screenWidth >= sm
					? "sm"
					: "xs";

		const config = titleSizeConfig[size] || titleSizeConfig.default;
		const {fontSize} = config.find(range => titleLength <= range.max);
		return {fontSize};
	}

	getAuthorStyle() {
		const {author, size} = this.props;
		if (size === "lg" && author.length <= 20) return {fontSize: 16};
		return {fontSize: 12};
	}

	render() {
		const {image, title, author, link, selected, openInNew} = this.props;

		if (!image) {
			return (
				<div
					className="BookImage book-img-container v-center"
					onClick={this.handleClick}
				>
					{this.renderSelected(selected)}
					{this.renderInnerImage(
						link,
						openInNew,
						<div className="book-img book-img-generated">
							<div>
								<p className="book-img-title" style={this.getTitleStyle(title)}>
									{title}
								</p>
								<p
									className="book-img-author"
									style={this.getAuthorStyle(author)}
								>
									by {author}
								</p>
							</div>
							<Books className="book-stack" />
						</div>,
					)}
				</div>
			);
		}
		return (
			<div className="BookImage book-img-container" onClick={this.handleClick}>
				<div className="book-img-inner-container">
					{this.renderSelected(selected)}
					{this.renderInnerImage(
						link,
						openInNew,
						<img
							alt={`cover of ${title}`}
							className="img-fluid book-img"
							src={image}
							style={{width: "100%"}}
						/>,
					)}
				</div>
			</div>
		);
	}
}

BookImage.defaultProps = {
	openInNew: false,
	size: "sm",
};

BookImage.propTypes = itemType;

export default withScreenWidth(BookImage);
