import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Form, FormFeedback} from "reactstrap";
import PropTypes from "prop-types";
import v from "validator";

import {formType} from "../types";
import {getAccount, updateAccount} from "../actions";
import Button from "../components/lib/Button";
import Input from "../components/lib/Input";

class AccountSettingsForm extends Component {
	componentDidMount() {
		this.props.getAccount();
	}
	onSubmit = formValues => {
		return this.props.updateAccount(formValues);
	};
	responseMessage(submitSucceeded, error) {
		if (submitSucceeded) {
			return (
				<FormFeedback valid={true} style={{display: "block"}}>
					Your account was updated successfully.
				</FormFeedback>
			);
		}
		return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
	}
	render() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
			initialValues,
		} = this.props;
		if (initialValues === null) {
			return null;
		}
		return (
			<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
				<Field
					id="email"
					name="email"
					component={Input}
					placeholder="Email"
					label="Email"
				/>
				<Field
					id="username"
					name="username"
					component={Input}
					placeholder="Username"
					label="Username"
				/>
				<Field
					id="firstName"
					name="firstName"
					component={Input}
					placeholder="First name"
					label="First name"
				/>
				<Field
					id="lastName"
					name="lastName"
					component={Input}
					placeholder="Last name"
					label="Last name"
				/>
				<Button
					id="submitSettingsForm"
					name="submit"
					disabled={submitting}
					color="primary"
				>
					Update Account
				</Button>
				{!(submitSucceeded || submitFailed)
					? null
					: this.responseMessage(submitSucceeded, error)}
			</Form>
		);
	}
}

AccountSettingsForm.propTypes = {
	...formType,
	loggedIn: PropTypes.bool,
	email: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	username: PropTypes.string,
	initialValues: PropTypes.shape({
		email: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		username: PropTypes.string,
	}),
	updateAccount: PropTypes.func.isRequired,
};

const validate = formValues => {
	const {email = "", username = "", firstName = ""} = formValues;
	let errors = {};
	if (email === null || !v.isEmail(email)) {
		errors.email = "Please enter a valid email";
	}
	if (username === "") {
		errors.username = "Please enter a username";
	}
	if (firstName === "") {
		errors.firstName = "Please enter a name";
	}
	return errors;
};

const formWrapped = reduxForm({
	form: "accountSettingsForm",
	validate,
})(AccountSettingsForm);

function mapStateToProps({
	account: {loggedIn, email, firstName, lastName, username, goodreadsId},
}) {
	const dataLoaded =		goodreadsId !== null
		|| firstName !== null
		|| lastName !== null
		|| email !== null;
	return {
		loggedIn,
		initialValues: dataLoaded ? {email, firstName, lastName, username} : null,
	};
}

export default connect(mapStateToProps, {getAccount, updateAccount})(formWrapped);
