import React, {Component} from "react";
import {Row, Col} from "reactstrap";
import {Link} from "react-router-dom";

import {addPageHead} from "../config";
import InfoPage from "../components/InfoPage";
import OutboundLink from "../components/OutboundLink";

class Disclaimer extends Component {
	render() {
		return (
			<InfoPage>
				{addPageHead("Disclaimer")}
				<Row>
					<Col md={{size: 10, offset: 1}}>
						<h1>Disclaimer</h1>
						<p>
							Your privacy is important to us. It is How Long to Read&apos;s
							policy to respect your privacy regarding any information we may
							collect from you across our website,{" "}
							<Link to="/" className="tertiary">
								https://howlongtoread.com
							</Link>
							.
							<br />
							<br />
							We only ask for personal information when we truly need it to
							provide a service to you. We collect it by fair and lawful means,
							with your knowledge and consent. We also let you know why we’re
							collecting it and how it will be used. We only retain collected
							information for as long as necessary to provide you with your
							requested service. What data we store, we’ll protect within
							commercially acceptable means to prevent loss and theft, as well
							as unauthorized access, disclosure, copying, use or modification.
							<br />
							<br />
							We don’t share any personally identifying information publicly or
							with third-parties, except when required to by law. Our website
							links to external sites that are not operated by us. Please be
							aware that we have no control over the content and practices of
							these sites, and cannot accept responsibility or liability for
							their respective privacy policies.
							<br />
							<br />
							We use Google Analytics to collect audience data such as country,
							search terms, usage, and other information to better understand
							how our users engage with How Long to Read. You can opt out of
							automated information collection by 3rd party ad networks through
							Google’s Analytics Opt-out browser add-on, available{" "}
							<OutboundLink
								eventLabel="ga-tools"
								to="https://tools.google.com/dlpage/gaoptout"
								target="_blank"
								rel="noopener noreferrer"
								className="tertiary"
							>
								here
							</OutboundLink>
							.
							<br />
							<br />
							You are free to refuse our request for your personal information,
							with the understanding that we may be unable to provide you with
							some of your desired services.
							<br />
							<br />
							How Long to Read retrieves book data and other information from{" "}
							<OutboundLink
								eventLabel="goodreads"
								to="https://goodreads.com/"
								target="_blank"
								rel="noopener noreferrer"
								className="tertiary"
							>
								GoodReads.
							</OutboundLink>
							<br />
							<br />
							As a member of Amazon’s Associates program, How Long to Read earns
							money from qualifying purchases that are made when you visit
							Amazon through a link on our site.
							<br />
							<br />
							Your continued use of our website will be regarded as acceptance
							of our practices around privacy and personal information. If you
							have any questions about how we handle user data and personal
							information, feel free to contact us.
							<br />
							<br />
							This policy is effective as of January 1st, 2020.
						</p>
					</Col>
				</Row>
			</InfoPage>
		);
	}
}

export default Disclaimer;
