import React, {Component} from "react";
import {Col} from "reactstrap";

import "../scss/ResetPassword.scss";
import {addPageHead} from "../config";
import FormPage from "../components/FormPage";
import ResetPasswordRequestForm from "../components/ResetPasswordRequestForm";
import ManLounging from "../components/img/ManLounging";

class ResetPasswordRequest extends Component {
	render() {
		return (
			<FormPage image={<ManLounging />}>
				{addPageHead("Log In")}
				<Col>
					<h1>Reset Password</h1>
					<ResetPasswordRequestForm />
				</Col>
			</FormPage>
		);
	}
}

export default ResetPasswordRequest;
