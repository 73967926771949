import React from "react";
import {Link} from "react-router-dom";
import Button from "../components/lib/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NewShelfButton = props => (
	<Link {...props} to="/account/books/new">
		<Button color="primary" id="btn-new-bookshelf" className="btn-small">
			<FontAwesomeIcon icon={["fas", "books-medical"]} />
			New Bookshelf
		</Button>
	</Link>
);

export default NewShelfButton;
