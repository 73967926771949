import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col} from "reactstrap";
import parseHtml from "html-react-parser";
import PropTypes from "prop-types";

import "../scss/ReadingTest.scss";
import {bookType} from "../types";
import {
	AVERAGE_WPM,
	humanize,
	humanizeSeconds,
	formatNumber,
	calculateWordCountFromParagraph,
} from "../config";
import {updateReadingSpeedTest} from "../actions";
import AmazonButton from "./AmazonButton";
import Button from "./lib/Button";

class ReadingTest extends Component {
	state = {
		started: false,
		startTime: null,
		readingTime: null,
		modal: false,
		showBookDescriptionForTest: false,
	};
	constructor(props) {
		super(props);
		this.toggleModal = this.toggleModal.bind(this);
	}

	calculateWpm(readingTime, wordCount) {
		return wordCount / (readingTime / 1000.0 / 60.0);
	}

	toggleTimer() {
		this.setState(prevState => {
			let newState = {
				...prevState,
				started: !prevState.started,
				startTime: Date.now(),
			};
			if (prevState.started) {
				const ms = Date.now() - prevState.startTime;
				newState.readingTime = ms;
				newState.startTime = null;
				newState.modal = true;
				if (!prevState.showBookDescriptionForTest) {
					this.props.updateReadingSpeedTest({ms});
				}
			}
			return newState;
		});
	}
	toggleTextOrDescription() {
		this.setState(prevState => {
			let newState = {
				...prevState,
				showBookDescriptionForTest: !prevState.showBookDescriptionForTest,
			};
			return newState;
		});
	}

	toggleModal() {
		this.setState({modal: !this.state.modal});
	}
	renderResultModal() {
		const {wordCount, book = null, modalClassName} = this.props;
		const {showBookDescriptionForTest, modal} = this.state;
		let wordCountToComputeWpm = wordCount;
		if (showBookDescriptionForTest) {
			wordCountToComputeWpm = calculateWordCountFromParagraph(book?.description);
		}

		const wpm = this.calculateWpm(
			this.state.readingTime,
			wordCountToComputeWpm,
		);

		return (
			<Modal
				isOpen={modal}
				toggle={this.toggleModal}
				className={modalClassName}
				centered={true}
			>
				<ModalHeader toggle={this.toggleModal}>Speed Test Results</ModalHeader>
				<ModalBody>
					You read <b>{humanize(wordCount, "word")}</b> in{" "}
					<b>{formatNumber(this.state.readingTime / 1000, 2)} seconds.</b> Your
					recorded speed for this passage was{" "}
					<b>{humanize(formatNumber(wpm, 0), "word")} per minute.</b>
					{book === null || !(book.averageReadingTime > 0)
						? null
						: parseHtml(` It will take you <b>${humanizeSeconds((book.averageReadingTime * AVERAGE_WPM) / wpm)}</b> to read ${book.title}.`)}
				</ModalBody>
				{book === null || !("asin" in book && book.asin !== null) ? null : (
					<ModalFooter>
						<AmazonButton asin={book.asin} />
					</ModalFooter>
				)}
			</Modal>
		);
	}
	render() {
		const {
			book,
			text = null,
			showTitle = true,
			showTopButton = true,
		} = this.props;
		const {showBookDescriptionForTest} = this.state;
		if (text === null) {
			return null;
		}
		const startText = (
			<Fragment>
				<FontAwesomeIcon icon={["fas", "play"]} />
				Start Timer
			</Fragment>
		);
		const stopText = (
			<Fragment>
				<FontAwesomeIcon icon={["fas", "stop"]} />
				Stop Timer
			</Fragment>
		);

		return (
			<Fragment>
				{showTitle ? <h2>Reading Speed Test</h2> : null}
				<p className="test-subtitle text-primary">
					Important: You should read the sample as you would a book, so
					don&apos;t speed-read
				</p>
				{!showTopButton ? null : (
					<Row className="test-row">
						<Col
							md={8}
							lg={6}
							sm={{size: 6, order: 2}}
							style={{marginBottom: 24}}
						>
							<Button color="primary" onClick={this.toggleTimer.bind(this)}>
								{!this.state.started ? startText : stopText}
							</Button>
						</Col>
						{book?.description ? (
							<Col md={8} lg={6} sm={{size: 6, order: 2}}>
								<Button
									color="primary"
									onClick={this.toggleTextOrDescription.bind(this)}
								>
									{!showBookDescriptionForTest
										? "Read Book Description"
										: "Read Random Text"}
								</Button>
							</Col>
						) : null}
					</Row>
				)}
				<p className="test-text">
					{parseHtml(showBookDescriptionForTest ? book?.description : text)}
				</p>
				<Row className="test-row justify-content-between">
					<Col md={8} lg={6}>
						<Button color="primary" onClick={this.toggleTimer.bind(this)}>
							{!this.state.started ? startText : stopText}
						</Button>
					</Col>
				</Row>
				{this.renderResultModal()}
			</Fragment>
		);
	}
}

ReadingTest.defaultProps = {
	showTitle: true,
	showTopButton: true,
	book: null,
	id: null,
};

ReadingTest.propTypes = {
	id: PropTypes.number,
	text: PropTypes.string.isRequired,
	showTitle: PropTypes.bool.isRequired,
	showTopButton: PropTypes.bool.isRequired,
	updateReadingSpeedTest: PropTypes.func.isRequired,
	book: PropTypes.shape(bookType),
	wordCount: PropTypes.number,
	modalClassName: PropTypes.string,
};

export default connect(null, {updateReadingSpeedTest})(ReadingTest);
