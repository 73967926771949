import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import "../scss/Book.scss";
import {bookUrl, addPageHead} from "../config";
import {getBook} from "../actions";
import {bookDetailsErrorType, bookDetailsType} from "../types";
import Spinner from "../components/lib/Spinner";

class Book extends Component {
	componentDidMount() {
		const {isbn} = this.props.match.params;
		this.props.getBook(undefined, isbn);
	}

	render() {
		if (this.props.details !== null) {
			const {id, title} = this.props.details;
			return <Redirect to={bookUrl(id, title)} />;
		}
		// This is to support legacy redirects. We can assume this is the entrypoint to the site and that state is empty
		const {bookDetailsError} = this.props;
		if (bookDetailsError !== null) {
			const {statusCode, message} = bookDetailsError;
			const searchParams = new URLSearchParams();
			searchParams.append("status", statusCode);
			searchParams.append("_error", message);
			const queryString = searchParams.toString();
			return <Redirect to={`/error?${queryString}`} />;
		}
		return (
			<div className="main">
				{addPageHead()}
				<div className="book-details-content-wrapper">
					<div className="book-details-content">
						<section className="book-details">
							<Spinner />
						</section>
					</div>
				</div>
			</div>
		);
	}
}

Book.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			isbn: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	details: bookDetailsType,
	bookDetailsError: bookDetailsErrorType,
	getBook: PropTypes.func.isRequired,
};

function mapStateToProps({book: {details, bookDetailsError}}) {
	return {details, bookDetailsError};
}

export default connect(mapStateToProps, {
	getBook,
})(Book);
