import React from "react";
import PropTypes from "prop-types";

import {humanizeSecondsShort} from "../config";
import Collection from "./Collection";
import BookImage from "./BookImage";

class BookCollection extends Collection {
	renderItem = item => {
		const {averageReadingTime, personalizedReadingTime} = item;
		const time = personalizedReadingTime || averageReadingTime;
		const {showReadingTime} = this.props;
		const image = item.smallImage || item.image || null;
		return (
			<div key={item.id} className="item-container d-flex flex-column">
				<BookImage {...item} image={image} link={`/books/${item.id}`} />
				{!(showReadingTime && time) ? null : (
					<p className="collection-subtitle">
						Time:{" "}
						<span className="secondary">{humanizeSecondsShort(time)}</span>
					</p>
				)}
			</div>
		);
	};
}

BookCollection.defaultProps = {
	showReadingTime: true,
};

BookCollection.propTypes = {
	showReadingTime: PropTypes.bool.isRequired,
};

export default BookCollection;
