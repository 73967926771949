import React from "react";
import moment from "moment";
import url from "url";
import duration from "parse-duration";
import {Helmet} from "react-helmet";
import ga4 from "react-ga4";

const AMAZON_TAG = "hltr-20";

export const GOODREADS_KEY = "Ww9C6Z7P8UJrcYehDfNEFg";
export const LOCAL_API_URL = "http://localhost:3000";
// export const LOCAL_API_URL = "https://api.howlongtoread.com/";
export const PROD_API_URL = "https://api.howlongtoread.com/";
export const ORIGIN_URL = "https://howlongtoread.com/";
let apiUrl;
if (process.env.NODE_ENV === "development") {
	apiUrl = LOCAL_API_URL;
} else {
	apiUrl = PROD_API_URL;
}
export const API_URL = apiUrl;

const TRACKING_ID = "UA-153139544-1";

const isProduction = process.env.NODE_ENV === "production";

export const init = () =>
	ga4.initialize(TRACKING_ID, {
		testMode: !isProduction,
	});

export const sendPageview = path =>
	ga4.send({
		hitType: "pageview",
		page: path,
	});

export const AVERAGE_WPM = 300.0;
export const DEFAULT_PASSAGE =	"It was a bright cold day in April, and the clocks were striking thirteen. Winston Smith, his chin nuzzled into his breast in an effort to escape the vile wind, slipped quickly through the glass doors of Victory Mansions, though not quickly enough to prevent a swirl of gritty dust from entering along with him.\r\nThe hallway smelt of boiled cabbage and old rag mats. At one end of it a coloured poster, too large for indoor display, had been tacked to the wall. It depicted simply an enormous face, more than a metre wide: the face of a man of about forty-five, with a heavy black moustache and ruggedly handsome features. Winston made for the stairs. It was no use trying the lift. Even at the best of times it was seldom working, and at present the electric current was cut off during daylight hours. It was part of the economy drive in preparation for Hate Week. The flat was seven flights up, and Winston, who was thirty-nine and had a varicose ulcer above his right ankle, went slowly, resting several times on the way. On each landing, opposite the lift-shaft, the poster with the enormous face gazed from the wall. It was one of those pictures which are so contrived that the eyes follow you about when you move. BIG BROTHER IS WATCHING YOU, the caption beneath it ran.";

export function bookUrl(id, title = "") {
	if (title) {
		const formattedTitle = title
			.trim()
			.split(" ")
			.slice(0, 10)
			.map(w => w.replace(/\W/g, ""))
			.join("-");
		return `/books/${id}/${formattedTitle}`;
	}
	return `/books/${id}`;
}

export function humanize(value, singularUnitName, optionalPlural = null) {
	const pluralUnitName = optionalPlural || `${singularUnitName}s`;
	return `${value} ${value === 1 ? singularUnitName : pluralUnitName}`;
}

const deconstructSeconds = seconds => {
	const duration = moment.duration(seconds * 1000);
	const days = Math.floor(duration.asDays());
	const hours = duration.hours();
	const minutes = duration.minutes();
	return [days, hours, minutes];
};

export function humanizeSeconds(seconds) {
	const [days, hours, minutes] = deconstructSeconds(seconds);

	const dayStr = humanize(days, "day");
	const hourStr = humanize(hours, "hour");
	const minuteStr = humanize(minutes, "minute");

	if (days === 0 && hours === 0 && minutes < 1) {
		return "N/A";
	}
	if (days > 0) {
		return `${dayStr}, ${hourStr}, and ${minuteStr}`;
	}
	if (hours > 0) {
		return `${hourStr} and ${minuteStr}`;
	}
	return minuteStr;
}

export function humanizeSecondsShort(seconds) {
	const [days, hours, minutes] = deconstructSeconds(seconds);

	if (days === 0 && hours === 0 && minutes < 1) {
		return "N/A";
	}
	if (days > 0) {
		return `${days}d${hours}h${minutes}m`;
	}
	if (hours > 0) {
		return `${hours}h ${minutes}m`;
	}
	return humanize(minutes, "min");
}

export function replaceAll(s, find, replace = "") {
	const re = new RegExp(find, "g");
	return s.replace(re, replace);
}

export function strToDuration(str) {
	const aliases = {
		".5": [
			" and a half",
			"half of an",
			"half of a",
			"half an",
			"half a",
			"half",
		],
	};
	let temp = str;
	for (let num in aliases) {
		if (!Object.prototype.hasOwnProperty.call(aliases, num)) continue;
		temp = aliases[num].reduce(
			(accumulator, cur) => replaceAll(accumulator, cur, num),
			str,
		);
	}
	return duration(temp) / 1000;
}

export function formatNumber(num, decimals = 0) {
	const factor = 10.0 ** parseInt(decimals);
	const rounded = Math.round(num * factor) / factor;
	return new Intl.NumberFormat().format(rounded);
}

export function amazonLink(asin, title = "") {
	if (!asin && !title) {
		return null;
	}
	if (asin) {
		const url = new URL(asin, "https://amazon.com/dp/");
		url.search = new URLSearchParams({tag: AMAZON_TAG});
		return url.href;
	}
	const url = new URL("https://www.amazon.com/s");
	url.search = new URLSearchParams({k: title, tag: AMAZON_TAG});
	return url.href;
}

export const goodreadsUrl = url.resolve(API_URL, "/auth/goodreads/");
export const linkGoodreadsAccountUrl = url.resolve(
	API_URL,
	"/auth/goodreads/auth-code/",
);

export const isExternalLink = link => link[0] !== "/";

export const addPageHead = (title, description, omitSiteName = false) => {
	let formattedTitleText = "How Long to Read";
	if (title) {
		if (omitSiteName) {
			formattedTitleText = title;
		} else {
			formattedTitleText = `${title} - How Long to Read`;
		}
	}
	return (
		<Helmet>
			<title>{formattedTitleText}</title>
			{description ? <meta name="description" content={description} /> : null}
		</Helmet>
	);
};

export const calculatePersonalTime = (personalSpeed, averageTime) => {
	const multiplier = AVERAGE_WPM / personalSpeed;
	const personalTime = Math.round(averageTime * multiplier);
	return personalTime;
};

export const calculateWordCountFromParagraph = paragraph => {
	if (!paragraph) return 0;
	const words = paragraph.split(/\s+/);

	// Filter out empty strings (caused by multiple spaces) and count the remaining words
	const wordCount = words.filter(word => word !== "").length;

	return wordCount;
};
