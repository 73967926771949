// pages
export const MY_BOOKS = "MY_BOOKS";
export const READING_SPEED = "READING_SPEED";
export const SETTINGS = "SETTINGS";
export const LOGOUT = "LOGOUT";

export const TABS = [
	{
		type: MY_BOOKS,
		name: "My Books",
		link: "/account/books",
	},
	{
		type: READING_SPEED,
		name: "Reading Speed",
		link: "/account/reading-speed",
	},
	{
		type: SETTINGS,
		name: "Settings",
		link: "/account/settings",
	},
];

// updating account
export const UPDATE_INFO = "UPDATE_INFO";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_3P_AUTH = "UPDATE_3P_AUTH";
export const UPDATE_EMAIL_PREFERENCES = "UPDATE_EMAIL_PREFERENCES";
